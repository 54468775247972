import React from 'react';
import { useSelector } from 'react-redux';

// Component
import Avatar from '@mui/material/Avatar';

const AvtarImage = (props) => {
  const { firstName, lastName } = useSelector((state) => state.auth);
  const fName = firstName || '';
  const lName = lastName || '';
  return (
    <Avatar className={props.className} sx={{ bgcolor: '#7367f0' }}>
      {`${fName.charAt(0).toLocaleUpperCase()}${lName.charAt(0).toLocaleUpperCase()}`}
    </Avatar>
  );
};

export default AvtarImage;
