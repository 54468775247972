import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Components
import DataTable from '../../components/DataTable';
import UserDetails from '../../components/popup/UserDetails';

// Constants
import {
  CANDIDATE_SOURCE_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  TABLE_NAME
} from '../../constants/common';
import { ADMIN_CANDIDATE_COLUMNS } from '../../constants/tableColumns';

// Redux-Actions
import { getCandidateList } from '../../redux/actions/candidates';
import { candidateDetailsAction } from '../../redux/actions/userDetails';

// Utils
import { debounce } from '../../utils/debounce';
import { wordCapitalize } from '../../utils/common';

// Apis
import { filteredHrListApi } from '../../redux/apis/hr';
import { getInterviewTechnology } from '../../redux/actions/hr';
import ReactTooltip from 'react-tooltip';

const AdminCandidatesList = () => {
  const dispatch = useDispatch();
  const technologyListData = useSelector((state) => state.hrs.interviewTechnologyType);
  const { candidates, userDetails } = useSelector((state) => state);
  const [candidate, setCandidate] = useState([]);
  const [candidateExport, setCandidateExport] = useState([]);
  const [hrs, setHrs] = useState([]);
  const [tableStates, setTableStates] = useState({
    searchValue: '',
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    sortBy: 'DateModified',
    orderBy: 'desc',
    minExp: '',
    maxExp: '',
    technology: '',
    filterByHR: ''
  });
  const [candidateId, setCandidateId] = useState(null);
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const onTogglePopup = () => setShowPopup((prevState) => !prevState);

  // Experience Year Filter
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getCandidateList(tableStates));
  }, [dispatch, tableStates, candidates?.isDeleted, candidates?.isUpdated, candidates?.isAdded]);

  useEffect(() => {
    const modifiedCandidate = candidates?.data?.map((candidateList) => {
      const hr = candidateList?.ReportsToUser || '';
      return {
        ...candidateList,
        HRName: hr?.FirstName + ' ' + hr?.LastName,
        // Interviewers: candidateList?.InterviewOfCandidate?.length
        //   ? candidateList?.InterviewOfCandidate?.flatMap((interview) =>
        //       interview.RoundOfInterview.flatMap((round) =>
        //         round.IRound.map(
        //           (ir) => `${ir?.Invited?.FirstName || ''} ${ir?.Invited?.LastName || ''}`
        //         )
        //       )
        //     ).join(', ')
        //   : '',
        CandidateName: (
          <Link
            to="#"
            className="table-view-popup-link"
            onClick={() => handleView(candidateList.ID)}>
            {`${wordCapitalize(candidateList.FirstName)} ${wordCapitalize(candidateList.LastName)}`}
          </Link>
        ),
        Technology: (
          <ul className="technology-list">
            {candidateList.UMap && candidateList.UMap.length > 0
              ? candidateList.UMap?.map((tech) => (
                  <li
                    key={tech.MapT.Technology}
                    style={{ backgroundColor: `${tech?.MapT?.TechnologyColor}` }}
                    className="technology-badge">
                    {tech.MapT.Technology}
                  </li>
                ))
              : []}
          </ul>
        ),
        CurrentCompany: candidateList?.CurrentCompany || '',
        Experience:
          `${candidateList.YearsOfExperience} Years` +
          `${
            candidateList?.MonthsOfExperience ? ` ${candidateList?.MonthsOfExperience} Months` : ''
          }`,
        Mobile: (
          <div className="center">
            <span>{candidateList.Mobile || '--'}</span>
            {candidateList.Mobile && (
              <>
                <i
                  data-tip="Copied"
                  data-event="click focus"
                  data-for={`copyIcon_${candidateList.Mobile}`}
                  id={`copyIcon_${candidateList.Mobile}`}
                  className="pi pi-fw pi-copy ml-1 copy-icon"
                />
                <ReactTooltip
                  id={`copyIcon_${candidateList.Mobile}`}
                  globalEventOff="click"
                  afterShow={() => {
                    navigator.clipboard.writeText(candidateList.Mobile);
                    setTimeout(() => {
                      ReactTooltip.hide();
                    }, 500);
                  }}
                />
              </>
            )}
          </div>
        )
      };
    });
    setCandidate(modifiedCandidate);
  }, [candidates]);

  useEffect(() => {
    const modifiedCandidate = candidates?.data?.map((candidateList) => {
      const name = `${wordCapitalize(candidateList.FirstName)} ${wordCapitalize(
        candidateList.LastName
      )}`;
      const hr = candidateList?.ReportsToUser || '';
      return {
        CandidateName: name,
        HRName: hr?.FirstName + ' ' + hr?.LastName,
        Technology:
          candidateList?.UMap && candidateList.UMap.length > 0
            ? candidateList.UMap.map((obj) => obj.MapT.Technology).join(', ')
            : '',
        Experience:
          `${candidateList.YearsOfExperience} Years` +
          `${
            candidateList?.MonthsOfExperience ? ` ${candidateList?.MonthsOfExperience} Months` : ''
          }`,
        Mobile: candidateList.Mobile || '--'
      };
    });
    setCandidateExport(modifiedCandidate);
  }, [candidates]);

  useEffect(() => {
    candidateId && dispatch(candidateDetailsAction(candidateId));
  }, [dispatch, candidateId, userDetails?.isCandidateUpdated]);

  // Call an API on component mount only to get the list of technologies.
  useEffect(() => {
    dispatch(getInterviewTechnology());
  }, [dispatch]);

  useEffect(() => {
    filteredHrListApi().then((res) => {
      if (res?.data?.data?.length) {
        let hrs = res.data.data.map((hr) => ({
          name: (hr?.FirstName || '') + ' ' + (hr?.LastName || ''),
          id: hr.ID
        }));
        setHrs(hrs);
      }
    });
  }, []);

  const modifyUserDetails = useCallback(() => {
    if (userDetails?.ID) {
      const candidateDetailsData = [
        {
          title: 'Name',
          value: `${wordCapitalize(userDetails?.FirstName) || ''}
              ${wordCapitalize(userDetails?.LastName) || ''}`
        },
        { title: 'Gender', value: userDetails?.Gender === 1 ? 'Male' : 'Female' || '' },
        {
          title: 'Experience',
          value:
            `${userDetails?.YearsOfExperience} Years` +
            `${userDetails?.MonthsOfExperience ? ` ${userDetails?.MonthsOfExperience} Months` : ''}`
        },
        {
          title: 'Email Address',
          value: (
            <div className="center">
              <span>{userDetails?.Email || '--'}</span>
              {userDetails?.Email && (
                <>
                  <i
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={`copyIcon_${userDetails?.Email}`}
                    id={`copyIcon_${userDetails?.Email}`}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={`copyIcon_${userDetails?.Email}`}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.Email);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Mobile',
          value: (
            <div className="center">
              <span>{userDetails?.Mobile || '--'}</span>
              {userDetails?.Mobile && (
                <>
                  <i
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={`copyIcon_${userDetails?.Mobile}`}
                    id={`copyIcon_${userDetails?.Mobile}`}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={`copyIcon_${userDetails?.Mobile}`}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.Mobile);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Second Mobile',
          value: (
            <div className="center">
              <span>{userDetails?.SecondaryMobile || '--'}</span>
              {userDetails?.SecondaryMobile && (
                <>
                  <i
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={`copyIcon_${userDetails?.SecondaryMobile}`}
                    id={`copyIcon_${userDetails?.SecondaryMobile}`}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={`copyIcon_${userDetails?.SecondaryMobile}`}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.SecondaryMobile);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Current Company',
          value: userDetails?.CurrentCompany || ''
        },
        {
          title: 'Technology',
          value:
            userDetails.CandidateTechnology && userDetails.CandidateTechnology.length > 0
              ? userDetails.CandidateTechnology?.map((tech) => tech.Technology).join(', ')
              : ''
        },
        {
          title: 'Current CTC',
          value: userDetails?.CurrentCTC ? `${userDetails?.CurrentCTC} LPA` : ''
        },
        {
          title: 'Candidate Location',
          value: userDetails?.Address || ''
        },
        {
          title: 'Candidate Source',
          value: CANDIDATE_SOURCE_TYPE.find((type) => type.code === userDetails?.CandidateSource)
            ?.name
        }
      ];
      // Only add the candidate reference name if candidate provided source is reference.
      if (
        userDetails?.CandidateSource ===
        CANDIDATE_SOURCE_TYPE[CANDIDATE_SOURCE_TYPE.length - 1].code
      ) {
        candidateDetailsData.push({
          title: 'Employee Ref Name',
          value: userDetails?.EmployeeReferenceName || ''
        });
      }
      candidateDetailsData.push(
        {
          title: 'Created By',
          value:
            (userDetails?.ReportsToUser?.FirstName || '') +
            ' ' +
            (userDetails?.ReportsToUser?.LastName || '')
        },
        {
          title: 'Last Updated Time',
          value: userDetails?.DateModified ? moment(userDetails?.DateModified).format('lll') : null
        }
      );
      setCandidateDetails(candidateDetailsData);
    }
  }, [userDetails]);

  useEffect(() => {
    modifyUserDetails();
  }, [userDetails, modifyUserDetails]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleFilterByHr = (filterByHR) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        filterByHR
      };
    });
  };

  const handleYearExperienceFilter = (minExp, maxExp) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        minExp,
        maxExp
      };
    });
    handleClose();
  };

  const handleView = (ID) => {
    if (ID) {
      onTogglePopup();
      setCandidateId(ID);
    }
  };

  const handleStatusFilter = (value) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        technology: value || ''
      };
    });
  };

  return (
    <>
      <UserDetails data={candidateDetails} onHide={onTogglePopup} show={showPopup} />
      <DataTable
        name={TABLE_NAME.CANDIDATE}
        searchPlaceHolder="Search by Candidate name"
        totalRecords={candidates.pagination.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onSort={handleSort}
        data={candidate}
        hrFilter
        hrs={hrs}
        onFilter={handleFilterByHr}
        selectedHrs={tableStates.filterByHR}
        exportData={candidateExport}
        columns={ADMIN_CANDIDATE_COLUMNS}
        experienceFilter
        open={open}
        label={
          tableStates.minExp && tableStates.maxExp
            ? `${tableStates.minExp} - ${tableStates.maxExp} Years`
            : 'Years Exp filter'
        }
        onExperienceFilter={handleClick}
        minExp={tableStates.minExp}
        maxExp={tableStates.maxExp}
        handleYearExperienceFilter={handleYearExperienceFilter}
        handleClose={handleClose}
        anchorEl={anchorEl}
        isDropdownMultiSelect
        dropdownPlaceHolder="Select Technology"
        onStatusFilter={handleStatusFilter}
        dropdown={
          technologyListData?.length
            ? technologyListData.map((tech) => ({ name: tech.Technology, value: tech.ID }))
            : []
        }
      />
    </>
  );
};

export default AdminCandidatesList;
