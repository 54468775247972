import React from 'react';

// Mui Components
import DatePicker from 'react-datepicker';
import moment from 'moment';

const CustomDatePicker = (props) => {
  const { minDateTime, minTime, maxTime } = props;
  return (
    <>
      <div style={{ width: '60%' }} className="pr-3">
        <DatePicker
          id="date"
          dateFormat="dd/MM/yyyy"
          placeholderText="Date"
          selected={props.value}
          onChange={(value) => props.onChange(value)}
          minDate={new Date(minDateTime)}
        />
      </div>
      <div style={{ width: '40%' }}>
        <DatePicker
          id="time"
          placeholderText="Time"
          selected={props.value}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          onChange={(value) => {
            const elements = document.getElementsByClassName('react-datepicker__time-list');
            const childValues = [];

            Array.from(elements).forEach((element) => {
              Array.from(element.children).forEach((child) => {
                childValues.push(child.textContent);
              });
            });

            const selectedTime = moment(value).format('h:mm A') || '';
            if (selectedTime) {
              let scrollToHeight = 0;
              childValues.some((v) => {
                if (
                  selectedTime === v ||
                  (selectedTime && v && selectedTime.toLowerCase() === v.toLowerCase())
                ) {
                  return true; // Exit loop if selected time is found
                } else {
                  scrollToHeight += 30; // Increment the scroll height
                }
              });
              if (selectedTime && elements[0].clientHeight < scrollToHeight) {
                elements[0].scrollTo(0, scrollToHeight);
              }
            }
            props.onChange(value);
          }}
          minTime={
            moment(props.value).isBefore(moment(minDateTime), 'day')
              ? maxTime // Disable if props.value's date is before minDateTime's date
              : moment(props.value).isAfter(moment(minDateTime), 'day')
              ? minTime // Enable if props.value's date is after minDateTime's date
              : new Date(minDateTime) // Set a specific time range if dates are the same
          }
          maxTime={maxTime}
        />
      </div>
    </>
  );
};

export default CustomDatePicker;
