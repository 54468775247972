import React, { useEffect, useRef, useState } from 'react';
import { EqualHeightElement } from 'react-equal-height';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Prime-React Components
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-datalabels';

// Components
import { CustomeTooltip } from './ActionButtons';

// Redux
import { getInterviewerRoundWithFilter } from '../redux/actions/admin';

//Constants
import { lightOptions } from '../constants/chartConstants';

// Images
import filterIc from '@assets/images/filter-ic.svg';
import filterIcDark from '@assets/images/filter-ic-dark.svg';

// Utils
import { getDateWithDay } from '../utils/date';

const DoughnutChart = () => {
  const menu = useRef(null);
  const dispatch = useDispatch();
  const { intervieweFilterdData, interviewRoundData } = useSelector(
    (state) => state.adminDashboard
  );
  const [Technology, setTechnology] = useState({});
  const [TechnologyFilter, setFilteredTechnology] = useState({});
  const [filter, setFilter] = useState('day');

  function getDataFromObject(object, field) {
    if (!Object.keys(object)?.length || !field) return [];

    return Object.keys(object).map((id) => object[id][field] || '');
  }

  // for technologies
  function modifiedTechnology(data) {
    let tech = {};
    const formattedData = (data || [])
      .map((item) => item?.Interview?.Candidate?.UMap)
      .flatMap((formattedItem) => formattedItem);
    if (!formattedData) {
      return tech;
    } else {
      for (var i = 0; i < formattedData.length; i++) {
        var el = formattedData[i];
        if (el?.MapT?.Technology) {
          const techCount = tech[el?.MapT?.ID] !== undefined ? tech[el?.MapT?.ID]?.Count + 1 : 1;
          tech[el?.MapT?.ID] = {
            Count: techCount,
            Technology: el.MapT.Technology,
            TechnologyColor: el.MapT.TechnologyColor
          };
        }
      }
      return tech;
    }
  }

  useEffect(() => {
    setTechnology(modifiedTechnology(interviewRoundData));
  }, [interviewRoundData]);

  useEffect(() => {
    setFilteredTechnology(modifiedTechnology(intervieweFilterdData));
  }, [intervieweFilterdData]);

  useEffect(() => {
    var startOfWeek = getDateWithDay(moment().startOf(filter).toDate());
    var endOfWeek = getDateWithDay(moment().endOf(filter).toDate());
    if (filter) {
      dispatch(getInterviewerRoundWithFilter(startOfWeek, endOfWeek));
    }
  }, [dispatch, filter]);

  const technologyFilter = [
    {
      label: 'Today',
      value: 1,
      command: () => setFilter('day'),
      className: filter === 'day' ? 'highlighted-item' : ''
    },
    {
      label: 'Weekly',
      value: 1,
      command: () => setFilter('week'),
      className: filter === 'week' ? 'highlighted-item' : ''
    },
    {
      label: 'Monthly',
      value: 2,
      command: () => setFilter('month'),
      className: filter === 'month' ? 'highlighted-item' : ''
    }
  ];

  const chartData = {
    labels: getDataFromObject(intervieweFilterdData ? TechnologyFilter : Technology, 'Technology'),
    datasets: [
      {
        data: getDataFromObject(intervieweFilterdData ? TechnologyFilter : Technology, 'Count'),
        borderWidth: 0,
        backgroundColor: getDataFromObject(
          intervieweFilterdData ? TechnologyFilter : Technology,
          'TechnologyColor'
        ),
        hoverBackgroundColor: getDataFromObject(
          intervieweFilterdData ? TechnologyFilter : Technology,
          'TechnologyColor'
        ),
        datalabels: {
          color: '#FFF',
          fontSize: 100
        }
      }
    ]
  };

  return (
    <div className="white-box-item small-width">
      <div className="white-box">
        <div className="card-title-block">
          <EqualHeightElement name="title">
            <div>
              <span className="card-title">Technology wise interviews</span>
              <i
                className="custom-target-icon pi pi-info-circle pl-1"
                data-tip={`${
                  filter === 'week'
                    ? "Last week's"
                    : filter === 'month'
                    ? "Last month's"
                    : "Today's"
                } Interviews based on Technology`}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </EqualHeightElement>
          <div className="filter-block">
            <Menu
              model={technologyFilter}
              popup
              ref={menu}
              id="popup_menu"
              appendTo="self"
              className="popup_menu action-popup chartFilterMenu"
            />
            <CustomeTooltip />
            <Button
              data-tip="Filter By"
              type="button"
              onClick={(event) => menu.current.toggle(event)}
              aria-controls="popup_menu"
              aria-haspopup
              className="p-button-rounded p-button-text border-none primary-icon-btn filter-btn">
              <i>
                <img src={filterIc} className="default-img" alt="filter" />
                <img src={filterIcDark} className="dark-mode-img" alt="filter" />
              </i>
            </Button>
          </div>
        </div>
        <div className="chart-figure-wrapper">
          <Chart
            id="technology-chart"
            type="doughnut"
            data={chartData}
            options={lightOptions}
            responsive="true"
            width="100%"
            height="230px"
            plugins={[ChartDataLabels]}
          />
        </div>
      </div>
    </div>
  );
};

export default DoughnutChart;
