import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

// Components
import Label from '../../components/Label';
import CustomButton from '../../components/CustomButton';

// Prime-React-Components
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import { Sidebar } from 'primereact/sidebar';

// Redux-Actions
import { addInterviewer, editInterviewer } from '../../redux/actions/interviewers';
import { checkEmailMobile, editUserDetailsAction } from '../../redux/actions/admin';
import { getInterviewTechnology } from '../../redux/actions/hr';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';

// Constants
import { BUTTON_TYPES, LABEL_TYPES, APLHABET_PATTERN_REGEX } from '../../constants/common';

// Utils
import { updateData } from '../../redux/slices/interviewers';
import moment from 'moment/moment';
import { technologyFilterbySubTechnology } from '../../utils/array';

const AddEditInterviewer = (props) => {
  const dispatch = useDispatch();
  const { onHide, show, ID } = props;
  const { userDetails } = useSelector((state) => state.adminDashboard);
  const interviewTechnologyType = useSelector((state) => state.hrs.interviewTechnologyType);
  const { isSucceed, isLoading } = useSelector((state) => state.apiStatus);
  const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false);
  const [interviewerData, setInterviewerData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    gender: '',
    yearOfExperience: '',
    monthsOfExperience: '',
    skills: []
  });

  useEffect(() => {
    dispatch(getInterviewTechnology());
  }, [dispatch]);

  useEffect(() => {
    if (ID) {
      dispatch(editUserDetailsAction(ID));
    }
  }, [dispatch, ID]);

  useEffect(() => {
    if (ID && userDetails && Object.keys(userDetails).length !== 0) {
      setInterviewerData({
        firstName: `${userDetails?.FirstName}`,
        lastName: `${userDetails?.LastName}`,
        email: `${userDetails?.Email}`,
        mobile: userDetails?.Mobile || '',
        gender: parseInt(userDetails.Gender),
        yearOfExperience: userDetails?.YearsOfExperience,
        monthsOfExperience: userDetails?.MonthsOfExperience,
        skills: userDetails?.UMapS?.length ? userDetails?.UMapS?.map((tech) => tech.MapST.ID) : []
      });
    }
  }, [userDetails, ID]);

  useEffect(() => {
    if (show && isUpdatedSuccess && isSucceed) {
      onHide();
      formik.resetForm();
      document.body.classList.remove('sidebar-open');
      setInterviewerData({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        gender: '',
        yearOfExperience: '',
        monthsOfExperience: '',
        skills: []
      });
      dispatch(updateData());
      dispatch(apiStatusClear());
      setIsUpdatedSuccess(false);
    }
  }, [isSucceed]);

  useEffect(() => () => setIsUpdatedSuccess(false), []);

  const formik = useFormik({
    initialValues: interviewerData,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, 'Must be 15 characters or less')
        .min(3, 'Must be 3 characters or more.')
        .matches(APLHABET_PATTERN_REGEX, 'First name should only contain alphabetical characters.')
        .trim()
        .required('First Name is required.'),
      lastName: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .min(3, 'Must be 3 characters or more.')
        .matches(APLHABET_PATTERN_REGEX, 'Last name should only contain alphabetical characters.')
        .trim()
        .required('Last Name is required.'),
      email: Yup.string().email('Invalid Email Address').required('Email Address is required.'),
      mobile: Yup.string().matches(/^[0-9]{10}$/, 'Mobile Number Must Be 10 Digits Only.'),
      gender: Yup.string(['1', '2']).required('Gender is required.'),
      yearOfExperience: Yup.number()
        .min(0, 'Minimum 0 Year.')
        .max(20, 'Maximum 20 Years.')
        .typeError('Only numbers are allowed.')
        .required('Exp. in Years is Required.'),
      monthsOfExperience: Yup.number()
        .min(0, 'Minimum 0 Month.')
        .max(11, 'Maximum 11 Months.')
        .required('Exp. in Months is required.')
        .typeError('Only numbers are allowed.'),
      skills: Yup.array()
        .of(Yup.string('Should be of type string.'))
        .min(1, 'Please select at least one skills.')
        .required('Skills are required.')
    }),
    onSubmit: (values) => {
      const formData = {
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        email: values.email,
        mobile: values.mobile,
        skills: values.skills.join(','),
        yearOfExperience: values.yearOfExperience,
        monthsOfExperience: values.monthsOfExperience,
        isHR: false
      };
      if (!formData.mobile) {
        delete formData.mobile;
      }
      if (ID) {
        if (
          formData.yearOfExperience !== userDetails?.YearsOfExperience ||
          formData.monthsOfExperience !== userDetails?.MonthsOfExperience
        ) {
          Object.assign(formData, {
            expirenceUPdatedDateTime: moment().toISOString()
          });
        }
        // Call the dispatch method to update the interviewer
        dispatch(
          editInterviewer({
            formData,
            InterviewerId: ID
          })
        );
      } else {
        Object.assign(formData, {
          expirenceUPdatedDateTime: moment().toISOString()
        });
        // Call the dispatch method to add new interviewer
        dispatch(addInterviewer(formData));
      }
      setIsUpdatedSuccess(true);
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <Sidebar
      ID={ID}
      visible={show}
      onHide={() => {
        onHide(true);
        document.body.classList.remove('sidebar-open');
      }}
      position="right"
      className="sidebar-drawer">
      <div className="form-box-wrapper">
        <div className="title-wrapper">
          <p className="card-title">{ID ? 'Edit' : 'Add'} Interviewers</p>
          <button
            className="p-sidebar-close"
            onClick={() => {
              onHide(false);
              document.body.classList.remove('sidebar-open');
              setInterviewerData({
                firstName: '',
                lastName: '',
                email: '',
                mobile: '',
                gender: '',
                yearOfExperience: '',
                monthsOfExperience: '',
                skills: []
              });
              formik.resetForm();
            }}>
            <span className="p-sidebar-close-icon pi pi-times" />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
          <div className="form-row-wrapper">
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label htmlFor="firstName" text={LABEL_TYPES.FIRST_NAME} isMandatory ishidden />
                  <InputText
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={LABEL_TYPES.FIRST_NAME + ' *'}
                    className={classNames({
                      'p-invalid': isFormFieldValid('firstName')
                    })}
                  />
                </div>
                {getFormErrorMessage('firstName')}
              </div>
            </div>
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label htmlFor="lastName" text={LABEL_TYPES.LAST_NAME} isMandatory ishidden />
                  <InputText
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={LABEL_TYPES.LAST_NAME + ' *'}
                    className={classNames({
                      'p-invalid': isFormFieldValid('lastName')
                    })}
                  />
                </div>
                {getFormErrorMessage('lastName')}
              </div>
            </div>
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label htmlFor="email" text={LABEL_TYPES.EMAIL} isMandatory ishidden />
                  <InputText
                    id="email"
                    name="email"
                    disabled={ID ? true : false}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      if (!formik.errors.email) {
                        dispatch(
                          checkEmailMobile({ email: e.target.value, ...(ID && { userId: ID }) })
                        );
                      }
                    }}
                    placeholder={LABEL_TYPES.EMAIL + ' *'}
                    className={classNames({
                      'p-invalid': isFormFieldValid('email')
                    })}
                  />
                </div>
                {getFormErrorMessage('email')}
              </div>
            </div>
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label htmlFor="mobile" text={LABEL_TYPES.MOBILE} isMandatory ishidden />
                  <InputText
                    id="mobile"
                    name="mobile"
                    keyfilter="int"
                    value={formik.values.mobile}
                    placeholder={LABEL_TYPES.MOBILE}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      if (!formik.errors.mobile) {
                        dispatch(
                          checkEmailMobile({ mobile: e.target.value, ...(ID && { userId: ID }) })
                        );
                      }
                    }}
                    className={classNames({
                      'p-invalid': isFormFieldValid('mobile')
                    })}
                  />
                </div>
                {getFormErrorMessage('mobile')}
              </div>
            </div>
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label htmlFor="gender" text={LABEL_TYPES.GENDER} isBold isMandatory />
                  <div className="radio-list-wrapper">
                    <div className="form-group-outer-radiobutton">
                      <RadioButton
                        inputId="male"
                        value={1}
                        name="gender"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.gender === 1}
                      />
                      <Label htmlFor="male" text={LABEL_TYPES.MALE} isBold />
                    </div>
                    <div className="form-group-outer-radiobutton">
                      <RadioButton
                        inputId="female"
                        value={2}
                        name="gender"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.gender === 2}
                      />
                      <Label htmlFor="female" text={LABEL_TYPES.FEMALE} isBold />
                    </div>
                  </div>
                </div>
                {getFormErrorMessage('gender')}
              </div>
            </div>
            <div className="form-col full-width">
              <div className="form-group-outer flex">
                <div className="custom-form-group mr-4">
                  <Label
                    htmlFor="yearOfExperience"
                    text={LABEL_TYPES.YEAR_EXPERIENCE}
                    isMandatory
                  />
                  <InputText
                    id="yearOfExperience"
                    name="yearOfExperience"
                    placeholder="0-20"
                    type="number"
                    value={formik.values.yearOfExperience}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={classNames({
                      'p-invalid': isFormFieldValid('yearOfExperience')
                    })}
                  />
                  {getFormErrorMessage('yearOfExperience')}
                </div>
                <div className="custom-form-group">
                  <Label
                    htmlFor="monthsOfExperience"
                    text={LABEL_TYPES.MONTH_EXPERIENCE}
                    isMandatory
                  />
                  <InputText
                    id="monthsOfExperience"
                    name="monthsOfExperience"
                    placeholder="0-11"
                    type="number"
                    value={formik.values.monthsOfExperience}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={classNames({
                      'p-invalid': isFormFieldValid('monthsOfExperience')
                    })}
                  />
                  {getFormErrorMessage('monthsOfExperience')}
                </div>
              </div>
            </div>
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <MultiSelect
                    id="skills"
                    name="skills"
                    filter
                    display="chip"
                    optionLabel="name"
                    optionValue="value"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    options={technologyFilterbySubTechnology(interviewTechnologyType)}
                    value={formik.values.skills}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={LABEL_TYPES.TECHNICAL_SKILLS + ' *'}
                    className={classNames({
                      'p-invalid': isFormFieldValid('skills')
                    })}
                  />
                </div>
                {getFormErrorMessage('skills')}
              </div>
            </div>
          </div>
          <div className="form-btn-wrapper">
            <CustomButton
              type="submit"
              variant="contained"
              className="ims-blue-btn"
              disabled={isLoading}>
              {BUTTON_TYPES.SAVE}
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={() => {
                onHide(false);
                document.body.classList.remove('sidebar-open');
                setInterviewerData({
                  firstName: '',
                  lastName: '',
                  email: '',
                  mobile: '',
                  gender: '',
                  yearOfExperience: '',
                  monthsOfExperience: '',
                  skills: []
                });
                formik.resetForm();
              }}
              className="gray-btn">
              {BUTTON_TYPES.CANCEL}
            </CustomButton>
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default AddEditInterviewer;
