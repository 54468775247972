import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components
import Label from '../../components/Label';
import CustomButton from '../../components/CustomButton';

// Prime-React Components
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Sidebar } from 'primereact/sidebar';

// Redux-Actions
import { addTechnology, editTechnology, getTechnologyById } from '../../redux/actions/technology';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { updateData } from '../../redux/slices/technology';
import { deleteSubTechnology } from '../../redux/apis/technology';

// Constants
import { BUTTON_TYPES, LABEL_TYPES } from '../../constants/common';

// Utils
import { wordCapitalize } from '../../utils/common';
import { Avatar } from 'primereact/avatar';
import { TECHNOLOGY_COLORS_OPTIONS } from '../../constants/dropdownOptions';
import { Dropdown } from 'primereact/dropdown';

const AddEditTechnology = (props) => {
  const dispatch = useDispatch();
  const { show, onHide, ID } = props;
  const { technologyDetails } = useSelector((state) => state.technology);
  const { isSucceed, isLoading } = useSelector((state) => state.apiStatus);
  const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false);

  const initialData = {
    technology: '',
    parameter: [],
    subTechnologies: [{ technology: '', technologyColor: '' }],
    techologyColor: ''
  };

  useEffect(() => {
    ID && dispatch(getTechnologyById(ID));
  }, [dispatch, ID]);

  useEffect(() => {
    if (show && isUpdatedSuccess && isSucceed) {
      onHide(false);
      formik.resetForm();
      document.body.classList.remove('sidebar-open');
      dispatch(updateData());
      dispatch(apiStatusClear());
      setIsUpdatedSuccess(false);
    }
  }, [isSucceed]);

  useEffect(() => () => setIsUpdatedSuccess(false), []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: Yup.object({
      technology: Yup.string()
        .trim()
        .required('Technology is required.')
        .max(100, 'Must be 100 characters or less'),
      parameter: Yup.array()
        .of(Yup.string('Parameter should be type of string.'))
        .required('Parameter is required.')
        .min(0, 'Minimum 0 Parameter is required.'),
      subTechnologies: Yup.array()
        .of(
          Yup.object().shape({
            technology: Yup.string().required('Technology is required.'),
            technologyColor: Yup.string().required('Technology color is required.')
          })
        )
        .required('Sub Technology is required.')
        .min(1, 'Minimum 1 Sub Technology is required.'),
      techologyColor: Yup.string()
        .required('Technology color is required.')
        .trim()
        .min(6, 'Minimun 6 Characters is required.')
        .max(7, 'Minimun 6 Characters is required.')
    }),

    onSubmit: (values) => {
      if (ID) {
        dispatch(
          editTechnology({
            formdata: {
              technology: values.technology,
              subTechnologies: values.subTechnologies,
              parameter: values.parameter.join(', '),
              technologyColor: values.techologyColor
            },
            ID: ID
          })
        );
      } else {
        dispatch(
          addTechnology({
            technology: values.technology,
            subTechnologies: values.subTechnologies,
            parameter: values.parameter && values.parameter.join(', '),
            technologyColor: values.techologyColor
          })
        );
      }
      setIsUpdatedSuccess(true);
    }
  });

  useEffect(() => {
    if (ID && technologyDetails && Object.keys(technologyDetails).length !== 0) {
      formik.setFieldValue('technology', technologyDetails?.Technology || []);
      formik.setFieldValue(
        'parameter',
        technologyDetails?.Parameters && technologyDetails?.Parameters.length > 0
          ? technologyDetails?.Parameters?.map((tech) => wordCapitalize(tech))
          : []
      );
      formik.setFieldValue(
        'subTechnologies',
        technologyDetails?.SubTechnologies?.length
          ? technologyDetails.SubTechnologies.map((sub) => ({
              id: sub.ID,
              technology: sub.Technology,
              technologyColor: sub.TechnologyColor
            }))
          : [{ technology: '', technologyColor: '' }]
      );

      formik.setFieldValue('techologyColor', technologyDetails?.TechnologyColor || '');
    }
  }, [technologyDetails]);

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const colorTemplete = (option) => {
    return (
      option && (
        <div className="center">
          <Avatar
            shape="circle"
            size="small"
            style={{ marginRight: 10, background: option.value }}
          />
          <span className="p-ml-2">{option.label}</span>
        </div>
      )
    );
  };

  const addNewSubTechnology = () => {
    formik.setFieldValue('subTechnologies', [
      ...formik.values.subTechnologies,
      { technology: '', technologyColor: '' }
    ]);
  };

  const removeSubTechnology = async (index, id) => {
    if (id) {
      await deleteSubTechnology(id).then((res) => {
        if (res.data.status) {
          formik.setFieldValue(
            'subTechnologies',
            formik.values.subTechnologies.filter((_, i) => i !== index)
          );
        }
      });
    } else {
      formik.setFieldValue(
        'subTechnologies',
        formik.values.subTechnologies.filter((_, i) => i !== index)
      );
    }
  };

  return (
    <Sidebar
      ID={ID}
      visible={show}
      onHide={() => {
        onHide(true);
        document.body.classList.remove('sidebar-open');
      }}
      position="right"
      className="sidebar-drawer">
      <div className="form-box-wrapper">
        <div className="title-wrapper">
          <p className="card-title">{ID ? 'Edit' : 'Add'} Technology</p>
          <button
            className="p-sidebar-close"
            onClick={() => {
              onHide(false);
              document.body.classList.remove('sidebar-open');
              formik.resetForm();
            }}>
            <span className="p-sidebar-close-icon pi pi-times" />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
          <div className="form-row-wrapper">
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label htmlFor="technology" text={LABEL_TYPES.TECHNOLOGY} isMandatory ishidden />
                  <InputText
                    id="technology"
                    name="technology"
                    value={formik.values.technology}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Add Technology *"
                    className={classNames({
                      'p-invalid': isFormFieldValid('technology')
                    })}
                  />
                </div>
                {getFormErrorMessage('technology')}
              </div>
            </div>
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label
                    htmlFor="technology-color"
                    text={LABEL_TYPES.COLOR_PICKER}
                    isMandatory
                    isBold
                  />
                  <Dropdown
                    id="techologyColor"
                    name="techologyColor"
                    value={formik.values.techologyColor}
                    options={TECHNOLOGY_COLORS_OPTIONS}
                    onChange={formik.handleChange}
                    optionLabel="label"
                    placeholder="Select a technology"
                    itemTemplate={colorTemplete}
                    valueTemplate={colorTemplete}
                  />
                  {getFormErrorMessage('techologyColor')}
                </div>
              </div>
            </div>
            <div className="form-col full-width">
              <Label
                htmlFor="technology"
                className="sub-technology"
                text="Sub Technology"
                isMandatory
              />
              {!!formik.values.subTechnologies?.length &&
                formik.values.subTechnologies.map((sub, index) => {
                  return (
                    <div className="full-width form-group-outer flex" key={index}>
                      <div className="custom-form-group mr-2 mb-4">
                        <InputText
                          id={`subTechnologies[${index}].technology`}
                          name={`subTechnologies[${index}].technology`}
                          value={sub.technology}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Add Technology *"
                        />
                        {!!(
                          formik.touched?.subTechnologies?.[index]?.technology &&
                          formik.errors?.subTechnologies?.[index]?.technology
                        ) && (
                          <small className="p-error">
                            {formik.errors?.subTechnologies?.[index]?.technology}
                          </small>
                        )}
                      </div>
                      <div className="custom-form-group sub-technology-color">
                        <Dropdown
                          id={`subTechnologies[${index}].technologyColor`}
                          name={`subTechnologies[${index}].technologyColor`}
                          value={sub.technologyColor}
                          options={TECHNOLOGY_COLORS_OPTIONS}
                          onChange={formik.handleChange}
                          optionLabel="label"
                          placeholder="Select a technology"
                          itemTemplate={colorTemplete}
                          valueTemplate={colorTemplete}
                        />
                        {!!(
                          formik.touched?.subTechnologies?.[index]?.technologyColor &&
                          formik.errors?.subTechnologies?.[index]?.technologyColor
                        ) && (
                          <small className="p-error">
                            {formik.errors?.subTechnologies?.[index]?.technologyColor}
                          </small>
                        )}
                      </div>
                      {formik.values.subTechnologies?.length > 1 && (
                        <div
                          className="mt-3 cursor-pointer"
                          onClick={() => removeSubTechnology(index, sub.id)}>
                          <i className="pi pi-fw pi-minus-circle"></i>
                        </div>
                      )}
                      {formik.values.subTechnologies?.length - 1 === index && (
                        <div className="mt-3 cursor-pointer" onClick={addNewSubTechnology}>
                          <i className="pi pi-fw pi-plus-circle"></i>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="form-btn-wrapper">
            <CustomButton type="submit" variant="contained" className="" disabled={isLoading}>
              {BUTTON_TYPES.SAVE}
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={() => {
                onHide(false);
                document.body.classList.remove('sidebar-open');
                formik.resetForm();
              }}
              color="error"
              className="gray-btn">
              {BUTTON_TYPES.CANCEL}
            </CustomButton>
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default AddEditTechnology;
