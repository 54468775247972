import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Editor } from '@tinymce/tinymce-react';

// Components
import Label from '../../components/Label';
import CustomButton from '../../components/CustomButton';

// Prime-React Components
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

// Redux-Actions
import {
  addJobDescription,
  editJobDescription,
  getJobDescriptionById
} from '../../redux/actions/jobDescriptions';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';

// Constants
import { BUTTON_TYPES, LABEL_TYPES, TINY_MCE_PLUGINS } from '../../constants/common';
import { updateData } from '../../redux/slices/jobDescriptions';
import { Dialog } from 'primereact/dialog';

const AddEditJobDescription = (props) => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const { show, ID, onHide } = props;
  const { descriptionDetails } = useSelector((state) => state.jobDescription);
  const { isSucceed, isLoading } = useSelector((state) => state.apiStatus);
  const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false);
  const [jobDesignationData, setJobDesignationData] = useState({
    name: '',
    subject: '',
    description: ''
  });

  useEffect(() => {
    ID && dispatch(getJobDescriptionById(ID));
  }, [dispatch, ID]);

  useEffect(() => {
    if (ID && descriptionDetails && Object.keys(descriptionDetails).length !== 0) {
      setJobDesignationData({
        name: `${descriptionDetails?.Title || ''}`,
        subject: `${descriptionDetails?.Subject || ''}`,
        description: `${descriptionDetails?.Description || ''}`
      });
    }
  }, [descriptionDetails]);

  useEffect(() => {
    if (show && isUpdatedSuccess && isSucceed) {
      onHide(false);
      formik.resetForm();
      dispatch(updateData());
      dispatch(apiStatusClear());
      setJobDesignationData({
        name: '',
        description: ''
      });
      setIsUpdatedSuccess(false);
    }
  }, [isSucceed]);

  useEffect(() => () => setIsUpdatedSuccess(false), []);

  const formik = useFormik({
    initialValues: jobDesignationData,
    validationSchema: Yup.object({
      name: Yup.string().trim().required('Name is required.'),
      subject: Yup.string().trim().required('Subject is required.'),
      description: Yup.string().trim().required('Description is required.')
    }),
    onSubmit: (values) => {
      if (ID) {
        dispatch(
          editJobDescription({
            formdata: {
              title: values.name,
              subject: values.subject,
              description: values.description
            },
            ID: ID
          })
        );
      } else {
        dispatch(
          addJobDescription({
            title: values.name,
            subject: values.subject,
            description: values.description
          })
        );
      }
      setIsUpdatedSuccess(true);
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <Dialog
      header={`${ID ? 'Edit/View' : 'Add'} Job Description`}
      visible={show}
      style={{ width: '850px' }}
      onHide={() => {
        onHide(false);
      }}>
      <div className="form-box-wrapper">
        <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
          <div className="form-row-wrapper">
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label for="name" text={LABEL_TYPES.JOB_DESCRIPTION_NAME} isMandatory />
                  <span className="p-input-icon-right">
                    <InputText
                      id="name"
                      name="name"
                      placeholder={LABEL_TYPES.JOB_DESCRIPTION_NAME}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid('name')
                      })}
                    />
                  </span>
                </div>
                {getFormErrorMessage('name')}
              </div>
            </div>
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label for="subject" text={LABEL_TYPES.JOB_DESCRIPTION_SUBJECT} isMandatory />
                  <span className="p-input-icon-right">
                    <InputText
                      id="subject"
                      name="subject"
                      placeholder={LABEL_TYPES.JOB_DESCRIPTION_SUBJECT}
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid('subject')
                      })}
                    />
                  </span>
                </div>
                {getFormErrorMessage('subject')}
              </div>
            </div>
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label for="description" text={LABEL_TYPES.DESCRIPTION} isMandatory />
                  <Editor
                    id="description"
                    name="description"
                    onInit={(_evt, editor) => (editorRef.current = editor)}
                    value={formik.values.description}
                    init={{
                      height: 500,
                      menubar: true,
                      branding: false,
                      plugins: TINY_MCE_PLUGINS
                    }}
                    onEditorChange={(editor) => formik.setFieldValue('description', editor)}
                  />
                </div>
                {getFormErrorMessage('description')}
              </div>
            </div>
          </div>
          <div className="form-btn-wrapper justify-flex-end">
            <CustomButton
              variant="contained"
              onClick={() => {
                onHide(false);
                setJobDesignationData({
                  name: '',
                  subject: '',
                  description: ''
                });
                formik.resetForm();
              }}
              color="error"
              className="gray-btn">
              {BUTTON_TYPES.CANCEL}
            </CustomButton>
            <CustomButton type="submit" variant="contained" className="" disabled={isLoading}>
              {BUTTON_TYPES.SAVE}
            </CustomButton>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default AddEditJobDescription;
