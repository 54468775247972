import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

// Components
import DataTable from '../../components/DataTable';
import InterviewDetails from '../../components/popup/InterviewDetails';
import { ActionButtonsForInvitationInterview, ResumeButton } from '../../components/ActionButtons';

// Prime-React Components
import { confirmDialog } from 'primereact/confirmdialog';

// Redux-Actions
import {
  getInterviewInvitationsList,
  interviewInvitaionStatus
} from '../../redux/actions/interviews';
import { interviewerInterviewDetailAction } from '../../redux/actions/userDetails';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { getNotification } from '../../redux/slices/notification';
import { redirectionOnStatusChange } from '../../redux/slices/interviews';

// Constants
import { INTERVIEW_INVITATIONS_COLUMNS } from '../../constants/tableColumns';
import {
  ACTION_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  INTERVIEW_INVITATION_STATUS,
  INTERVIEW_MODE,
  INTERVIEW_TYPE,
  Interview_Invitations_Status,
  ROUTES,
  STATUS,
  TABLE_NAME
} from '../../constants/common';

// Prime-React Components
import { Badge } from 'primereact/badge';

// Utils
import { formatTime, getFormattedDateView } from '../../utils/date';
import { debounce } from '../../utils/debounce';
import { wordCapitalize, downloadPdf } from '../../utils/common';
import { INVITATIONS_DROPDOWN_OPTIONS } from '../../constants/dropdownOptions';
import ConfirmDialogWithReason from '../../components/ConfirmDialogWithReasonForInterview';
import ReactTooltip from 'react-tooltip';

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/resume`;

const InterviewInvitation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirection, invitationStatus } = useSelector((state) => state.interviews);
  const { interviewInvitaion, userDetails } = useSelector((state) => state);
  const { isNotification } = useSelector((state) => state.notification);
  const [invitationList, setInvitationList] = useState([]);
  const [invitationExport, setInvitationExport] = useState([]);
  const [interviewDetails, setInterviewDetails] = useState([]);
  const [interviewId, setInterviewId] = useState(null);
  const [invitationId, setInvitationId] = useState(null);
  const [interviewDate, setInterviewDate] = useState(null);
  const [displayConfirmAction, setDisplayConfirmAction] = useState(false);
  const [tableStates, setTableStates] = useState({
    searchValue: '',
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    invitationStatus: '',
    sortBy: 'Status',
    orderBy: 'asc'
  });
  const [showPopup, setShowPopup] = useState(false);
  const onTogglePopup = () => setShowPopup((prevState) => !prevState);

  useEffect(() => {
    dispatch(getInterviewInvitationsList(tableStates));
    if (isNotification) {
      dispatch(getNotification(false));
    }
  }, [dispatch, tableStates, isNotification]);

  const openResumeLink = (link) => window.open(link, '_blank');

  useEffect(() => {
    const modifiedList = interviewInvitaion?.data?.map((data) => {
      const { InterviewRound, RejectionReason, Status } = data;
      const { Interview, ID, Description, StartDateOfInterviewRound } = InterviewRound;
      return {
        ...data,
        CandidateName: (
          <Link to="#" className="table-view-popup-link" onClick={() => handleView(ID)}>
            {Interview?.Candidate?.FirstName ? wordCapitalize(Interview.Candidate?.FirstName) : ''}{' '}
            {Interview?.Candidate?.LastName ? wordCapitalize(Interview.Candidate?.LastName) : ''}
          </Link>
        ),
        HrName: `${
          InterviewRound.HROfRound?.FirstName
            ? wordCapitalize(InterviewRound.HROfRound?.FirstName)
            : ''
        } ${
          InterviewRound.HROfRound?.LastName
            ? wordCapitalize(InterviewRound.HROfRound?.LastName)
            : ''
        }`,
        'Start Date': `${getFormattedDateView(
          InterviewRound.StartDateOfInterviewRound
        )} ${formatTime(InterviewRound.StartDateOfInterviewRound)}`,
        InterviewType: (
          <ul className="technology-list">
            {Interview?.Candidate.UMap && Interview?.Candidate?.UMap?.length > 0
              ? Interview.Candidate.UMap?.map((tech) => (
                  <li
                    key={tech.MapT.Technology}
                    style={{ backgroundColor: `${tech?.MapT.TechnologyColor}` }}
                    className="technology-badge">
                    {tech.MapT.Technology}
                  </li>
                ))
              : []}
          </ul>
        ),
        JobDesignation:
          Interview?.JobDesignation && wordCapitalize(Interview?.JobDesignation?.Designation),
        RoundOfInterview: `${INTERVIEW_TYPE.filter(
          (num) => num.code === InterviewRound?.InterviewRound
        ).map((type) => type.name)}`,
        resume: (
          <div className="flex">
            {Interview?.Candidate?.ResumeOfCandidate?.FilePath && (
              <>
                <ResumeButton
                  onClick={() =>
                    openResumeLink(
                      `${pathBase}/${Interview?.Candidate?.ID}/${Interview?.Candidate?.ResumeOfCandidate?.FilePath}`
                    )
                  }
                />
                <i
                  data-tip="Resume Download"
                  onClick={() =>
                    downloadPdf(
                      pathBase,
                      Interview?.Candidate?.ID,
                      Interview?.Candidate?.ResumeOfCandidate?.FilePath
                    )
                  }
                  className="pi pi-fw pi-download"
                />
              </>
            )}
          </div>
        ),
        Status: (
          <div className="center interview-status">
            <Badge
              key={Status}
              value={INTERVIEW_INVITATION_STATUS[Status]}
              className={`white-space-nowrap ${
                Status === STATUS.ACCEPT
                  ? 'complete-badge'
                  : Status === STATUS.ACCEPT_BY_OTHER
                  ? 'reschedule-badge'
                  : Status === STATUS.PENDING
                  ? 'pending-badge'
                  : 'cancel-badge'
              }`}
            />
            {Status === Interview_Invitations_Status.Rejected && RejectionReason && (
              <i
                className="custom-target-icon pi pi-info-circle pl-1"
                data-tip={`Cancellation Reason : ${RejectionReason}`}
                style={{ cursor: 'pointer' }}
              />
            )}
            <ReactTooltip place="top" type="dark" effect="float" />
          </div>
        ),
        Description: Description || '',
        action: (
          <div className="flex align-items-center">
            <ActionButtonsForInvitationInterview
              disabled={Status !== STATUS.PENDING}
              onClick={(actionType, e) =>
                handleRowActions(
                  actionType,
                  InterviewRound.ID,
                  data.ID,
                  StartDateOfInterviewRound,
                  e
                )
              }
            />
          </div>
        )
      };
    });
    setInvitationList(modifiedList);
  }, [interviewInvitaion]);

  useEffect(() => {
    const modifiedList = interviewInvitaion?.data?.map((data) => {
      const { InterviewRound } = data;
      const { Interview } = InterviewRound;
      const name = `${
        Interview?.Candidate?.FirstName ? wordCapitalize(Interview.Candidate?.FirstName) : ''
      } ${Interview?.Candidate?.LastName ? wordCapitalize(Interview.Candidate?.LastName) : ''}`;
      const Type = Interview?.Candidate?.UMap?.length
        ? Interview?.Candidate?.UMap.map((item) => item.MapT.Technology).join(', ')
        : [];
      return {
        CandidateName: name,
        HrName: `${
          InterviewRound.HROfRound?.FirstName
            ? wordCapitalize(InterviewRound.HROfRound?.FirstName)
            : ''
        } ${
          InterviewRound.HROfRound?.LastName
            ? wordCapitalize(InterviewRound.HROfRound?.LastName)
            : ''
        }`,
        'Start Date': `${getFormattedDateView(
          InterviewRound.StartDateOfInterviewRound
        )} ${formatTime(InterviewRound.StartDateOfInterviewRound)}`,
        InterviewType: Type,
        JobDesignation:
          Interview?.JobDesignation && wordCapitalize(Interview?.JobDesignation?.Designation)
      };
    });
    setInvitationExport(modifiedList);
  }, [interviewInvitaion]);

  useEffect(() => {
    if (interviewDate && invitationId) {
      setDisplayConfirmAction(true);
    } else {
      setDisplayConfirmAction(false);
    }
  }, [invitationId, interviewDate]);

  const modifyInterviewDetails = useCallback(() => {
    if (userDetails?.Interview?.Candidate && userDetails?.HROfRound) {
      setInterviewDetails({
        'Interview Details': {
          'Start Date': `${getFormattedDateView(
            userDetails?.StartDateOfInterviewRound
          )} ${formatTime(userDetails?.StartDateOfInterviewRound || '')} `,
          'End Date': `${getFormattedDateView(userDetails?.EndDateOfInterviewRound)} ${formatTime(
            userDetails?.EndDateOfInterviewRound || ''
          )} `,
          'Interview Type': `${INTERVIEW_TYPE.filter(
            (num) => num.code === userDetails?.InterviewRound
          ).map((type) => type.name)}`,
          Technology:
            userDetails?.Interview?.Candidate.UMap &&
            userDetails?.Interview?.Candidate.UMap.length > 0
              ? userDetails?.Interview?.Candidate.UMap?.map((tech) =>
                  wordCapitalize(tech.MapT.Technology)
                ).join(', ')
              : null,
          'Interview Mode': userDetails?.InterviewRoundMode
            ? INTERVIEW_MODE.find((num) => num.code === userDetails?.InterviewRoundMode)?.name
            : '--'
        },
        'Candidate Details': {
          Name: `${wordCapitalize(userDetails?.Interview?.Candidate?.FirstName) || ''} ${
            wordCapitalize(userDetails?.Interview?.Candidate?.LastName) || ''
          }`,
          // Email: (
          //   <div className="center">
          //     <span>{userDetails?.Interview?.Candidate?.Email || '--'}</span>
          //     {userDetails?.Interview?.Candidate?.Email && (
          //       <>
          //         <i
          //           data-tip="Copied"
          //           data-event="click focus"
          //           data-for={`copyIcon_${userDetails?.Interview?.Candidate?.Email}`}
          //           id={`copyIcon_${userDetails?.Interview?.Candidate?.Email}`}
          //           className="pi pi-fw pi-copy ml-1 copy-icon"
          //         />
          //         <ReactTooltip
          //           id={`copyIcon_${userDetails?.Interview?.Candidate?.Email}`}
          //           globalEventOff="click"
          //           afterShow={() => {
          //             navigator.clipboard.writeText(userDetails?.Interview?.Candidate?.Email);
          //             setTimeout(() => {
          //               ReactTooltip.hide();
          //             }, 500);
          //           }}
          //         />
          //       </>
          //     )}
          //   </div>
          // ),
          Experience:
            `${userDetails?.Interview?.Candidate?.YearsOfExperience} Years` +
            `${
              userDetails?.Interview?.Candidate?.MonthsOfExperience
                ? ` ${userDetails?.Interview?.Candidate?.MonthsOfExperience} Months`
                : ''
            }`
        },
        'HR Details': {
          Name: `${userDetails?.HROfRound?.FirstName || ''} ${
            userDetails?.HROfRound?.LastName || ''
          }`
          // Email: (
          //   <div className="center">
          //     <span>{userDetails?.HR?.Email || '--'}</span>
          //     {userDetails?.HR?.Email && (
          //       <>
          //         <i
          //           data-tip="Copied"
          //           data-event="click focus"
          //           data-for={`copyIcon_${userDetails?.HR?.Email}`}
          //           id={`copyIcon_${userDetails?.HR?.Email}`}
          //           className="pi pi-fw pi-copy ml-1 copy-icon"
          //         />
          //         <ReactTooltip
          //           id={`copyIcon_${userDetails?.HR?.Email}`}
          //           globalEventOff="click"
          //           afterShow={() => {
          //             navigator.clipboard.writeText(userDetails?.HR?.Email);
          //             setTimeout(() => {
          //               ReactTooltip.hide();
          //             }, 500);
          //           }}
          //         />
          //       </>
          //     )}
          //   </div>
          // )
        }
      });
    }
  }, [userDetails]);

  useEffect(() => {
    modifyInterviewDetails();
  }, [userDetails, modifyInterviewDetails]);

  useEffect(() => {
    interviewId && dispatch(interviewerInterviewDetailAction(interviewId));
  }, [dispatch, interviewId]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleView = (ID) => {
    if (ID) {
      onTogglePopup();
      setInterviewId(ID);
    }
  };

  const handleRowActions = (actionType, rowId, acceptRejectId, date) => {
    if (actionType === ACTION_TYPE.ACCEPT) {
      const interviewDate = `${getFormattedDateView(date)} at ${formatTime(date)}`;
      const dateOfInterview = `${getFormattedDateView(interviewDate)}`;
      const TodaysDate = `${getFormattedDateView(new Date())}`;
      if (TodaysDate === dateOfInterview) {
        confirmDialog({
          message: (
            <>
              This interview is scheduled for <strong>{interviewDate}</strong> (TODAY). Are you sure
              you want to accept?
            </>
          ),
          header: 'Accept',
          icon: 'pi pi-exclamation-triangle',
          accept: () =>
            dispatch(
              interviewInvitaionStatus({ id: acceptRejectId, invitationStatus: STATUS.ACCEPT })
            )
        });
      } else {
        confirmDialog({
          message: (
            <>
              This interview is scheduled for <strong>{interviewDate}</strong>. Are you sure you
              want to accept?
            </>
          ),
          header: 'Accept',
          icon: 'pi pi-exclamation-triangle',
          accept: () =>
            dispatch(
              interviewInvitaionStatus({ id: acceptRejectId, invitationStatus: STATUS.ACCEPT })
            )
        });
      }
    } else if (actionType === ACTION_TYPE.REJECT) {
      const interviewDate = `${getFormattedDateView(date)} at ${formatTime(date)}`;
      setInvitationId(acceptRejectId);
      setInterviewDate(interviewDate);
    }
  };

  useEffect(() => {
    if (redirection) {
      setDisplayConfirmAction(false);
      if (invitationStatus == STATUS.ACCEPT) {
        navigate(ROUTES.INTERVIEWER.INTERVIEW_SCHEDULED);
        toast.success('Invitation Accepted Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch(apiStatusClear());
        dispatch(redirectionOnStatusChange({ redirection: false, invitationStatus }));
      } else {
        navigate(ROUTES.INTERVIEWER.DASHBOARD);
        dispatch(redirectionOnStatusChange({ redirection: false, invitationStatus }));
      }
    }
  }, [redirection, navigate, dispatch]);

  const handleFilter = (value) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        invitationStatus: value || ''
      };
    });
  };

  return (
    <>
      <InterviewDetails data={interviewDetails} onHide={onTogglePopup} show={showPopup} />
      <ConfirmDialogWithReason
        bodyTitle={
          <span>
            This interview is scheduled for <strong>{interviewDate}</strong>{' '}
            {getFormattedDateView(new Date()) === getFormattedDateView(interviewDate)
              ? ' (TODAY)'
              : ''}
            . Are you sure you want to reject?
          </span>
        }
        interviewId={invitationId}
        dialogHeader="Reject Invitation"
        setInterviewId={setInvitationId}
        displayConfirmAction={displayConfirmAction}
        isReasonRequired
        handleSubmit={(values) => {
          dispatch(
            interviewInvitaionStatus({
              id: invitationId,
              rejectionReason: values.cancelReason,
              invitationStatus: STATUS.REJECT
            })
          );
        }}
      />
      <DataTable
        name={TABLE_NAME.INTERVIEW_INVITATIONS}
        columns={INTERVIEW_INVITATIONS_COLUMNS}
        searchPlaceHolder="Search by Candidate name"
        data={invitationList}
        exportData={invitationExport}
        totalRecords={interviewInvitaion?.pagination?.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onSort={handleSort}
        dropdown={INVITATIONS_DROPDOWN_OPTIONS}
        onStatusFilter={handleFilter}
      />
    </>
  );
};

export default InterviewInvitation;
