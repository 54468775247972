import React, { useRef, useState, useEffect } from 'react';
import { put } from 'redux-saga/effects';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

// Components
import Label from '../../components/Label';
import CustomDatePicker from '../../components/CustomDatePicker';
import CustomButton from '../../components/CustomButton';
import { AvailabilityButton, ResumeButton } from '../../components/ActionButtons';
import AvailabilityDetails from '../../components/popup/AvailabilityDetails';

// date-fns
import addMinutes from 'date-fns/addMinutes';

// Prime-React Components
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import { InputSwitch } from 'primereact/inputswitch';
import { Sidebar } from 'primereact/sidebar';

// Redux-Actions
import { allInterviewers } from '../../redux/actions/showOptions';
import { editCandidate, getCandidateDetails, addCandidate } from '../../redux/actions/candidates';
import { getInterviewTechnology, getJobDesignationBySearch } from '../../redux/actions/hr';

// Constants
import {
  INTERVIEW_TYPE,
  BUTTON_TYPES,
  LABEL_TYPES,
  MSG_TYPES,
  TEXTAREA_LIMIT,
  CANDIDATE_SOURCE_TYPE,
  InterviewType,
  RENDER,
  INTERVIEW_CATEGORY,
  Interview_Category,
  InterviewMode,
  INTERVIEW_MODE
} from '../../constants/common';
import { NUMBER_OF_INTERVIEWERS } from '../../constants/dropdownOptions';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { setIsUpdatedSuccess, updateData } from '../../redux/slices/candidates';
import { updateCandidateData } from '../../redux/slices/userDetails';

// Utils
import { resumeValidation, wordCapitalize } from '../../utils/common';

// Apis
import { Link } from 'react-router-dom';
import { checkEmailMobile } from '../../redux/actions/admin';
import { technologyFilterbySubTechnology } from '../../utils/array';

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/resume`;
const AddEditCandidates = (props) => {
  // Props.
  const { onHide, show, ID, handleView } = props;

  // Redux hooks.
  const dispatch = useDispatch();
  const { candidateDetails, isUpdatedSuccess } = useSelector((state) => state.candidates);
  const { isSucceed, isLoading } = useSelector((state) => state.apiStatus);
  const { searchData, interviewTechnologyType } = useSelector((state) => state.hrs);
  const { options, candidates } = useSelector((state) => state);

  // React useState hooks.
  const [designationOptions, setdesignationOptions] = useState(null);
  const [designationText, setDesignationText] = useState('');
  const [fileName, setFileName] = useState('');
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMinutes(new Date().getMinutes() + 30))
  );
  const [interviewerOptions, setInterviewerOptions] = useState(null);
  const [techSearchText, setTechSearchText] = useState(null);
  const [isFile, setIsFile] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [candidateData, setCandidateData] = useState({
    firstName: '',
    lastName: '',
    gender: 1,
    resumeFilePath: '',
    email: '',
    mobile: '',
    secondaryMobile: '',
    yearOfExperience: '',
    monthsOfExperience: '',
    interviewType: '',
    interviewerID: '',
    interviewerLimit: NUMBER_OF_INTERVIEWERS[0],
    interviewStartDateTime: '',
    interviewEndDateTime: '',
    duration: 60,
    description: '',
    interviewTechnology: [],
    skills: [],
    jobDesignation: '',
    isEdit: true,
    isScheduleInterviewEnabled: false,
    currentCompany: '',
    currentCTC: '',
    candidateLocation: '',
    interviewRoundMode: InterviewMode['Tatvasoft Office'],
    interviewCategory: Interview_Category.Technical,
    candidateSource: CANDIDATE_SOURCE_TYPE[0]?.code || 1,
    referenceEmployeeName: ''
  });

  // React useRef hooks.
  const fileRef = useRef(null);

  // Functions.
  // const technologiesID = technologies && technologies.split(',');
  const onTogglePopup = () => setShowPopup((prevState) => !prevState);
  const openResumeLink = (link) => window.open(link, '_blank');
  const onDateChange = (value) => setStartDate(value);
  const handleDesignationSearch = () => {
    if (designationText) {
      const sanitizeString = designationText.trim().toString();
      dispatch(getJobDesignationBySearch(sanitizeString));
    }
  };

  useEffect(() => {
    if (!techSearchText) {
      const modifiedTechnologyOptions =
        interviewTechnologyType &&
        interviewTechnologyType.find(
          (technology) => technology.ID === candidateData.interviewTechnology
        );
      setTechSearchText(modifiedTechnologyOptions);
    }
  }, [interviewTechnologyType, candidateData]);

  useEffect(() => {
    dispatch(getInterviewTechnology());
  }, [dispatch]);

  const modifiedOptions = (options) => {
    if (!options?.length) return [];
    return options?.map((opt) => {
      return {
        ...opt,
        name: `${opt.FirstName} ${opt.LastName}`,
        code: `${opt.ID}`,
        key: `${opt.ID}`,
        disabled: !opt.IsAvailable,
        isAvailable: opt.IsAvailable
      };
    });

    // let optionArray = [];
    // if (Object.keys(options)?.length) {
    //   Object.keys(options).forEach((key) => {
    //     if (options[key]?.length) {
    //       let dividedOptions = options[key]?.map((opt) => {
    //         return {
    //           ...opt,
    //           name: `${opt.FirstName} ${opt.LastName}`,
    //           value: `${opt.ID}`,
    //           disabled: key === 'Hrs' ? false : !opt.IsAvailable,
    //           isAvailable: key === 'Hrs' ? true : opt.IsAvailable,
    //           key: `${opt.ID}`
    //         };
    //       });
    //       optionArray.push({
    //         label: key,
    //         code: key,
    //         items: dividedOptions
    //       });
    //     }
    //   });
    // }
    // return optionArray;
  };

  useEffect(() => {
    if (!ID) {
      const modifiedInterviewerOptions = modifiedOptions(options);
      setInterviewerOptions(modifiedInterviewerOptions);
    }
  }, [options, ID]);

  useEffect(() => {
    const modifiedDesignationOptions =
      searchData && searchData.length > 0 ? searchData : [{ Designation: MSG_TYPES.NO_RESULT }];
    setdesignationOptions(modifiedDesignationOptions);
  }, [searchData]);

  useEffect(() => {
    if (ID && candidateDetails && Object.keys(candidateDetails).length !== 0) {
      let candidateMobile = candidateDetails.Mobile;
      if (!candidateMobile || (candidateMobile && candidateMobile === 'null')) {
        candidateMobile = '';
      }
      setCandidateData((prevState) => ({
        ...prevState,
        isEdit: false,
        firstName: `${candidateDetails.FirstName}`,
        lastName: `${candidateDetails.LastName}`,
        email: candidateDetails.Email,
        mobile: candidateMobile,
        secondaryMobile: candidateDetails?.SecondaryMobile || '',
        gender: parseInt(candidateDetails.Gender),
        yearOfExperience: candidateDetails.YearsOfExperience,
        monthsOfExperience: candidateDetails.MonthsOfExperience,
        currentCompany: candidateDetails.CurrentCompany || '',
        currentCTC: candidateDetails.CurrentCTC || '',
        candidateLocation: candidateDetails.Address || '',
        candidateSource: candidateDetails.CandidateSource || '',
        referenceEmployeeName: candidateDetails.EmployeeReferenceName || '',
        interviewTechnology:
          candidateDetails.CandidateTechnology && candidateDetails.CandidateTechnology.length > 0
            ? candidateDetails.CandidateTechnology?.map((tech) => tech.ID)
            : [],
        skills:
          candidateDetails.CandidateSkills && candidateDetails.CandidateSkills.length > 0
            ? candidateDetails.CandidateSkills?.map((tech) => tech.ID)
            : []
      }));
      if (candidateDetails?.ResumeOfCandidate?.FilePath) {
        setIsFile(candidateDetails?.ResumeOfCandidate?.FilePath);
      } else {
        setIsFile('');
      }
    }
  }, [candidateDetails, ID]);

  const clearData = () => {
    onHide(false);
    document.body.classList.remove('sidebar-open');
    setCandidateData({
      firstName: '',
      lastName: '',
      gender: 1,
      resumeFilePath: '',
      email: '',
      mobile: '',
      secondaryMobile: '',
      yearOfExperience: 0,
      monthsOfExperience: 0,
      interviewType: InterviewType['Technical Interview - 1'],
      interviewerID: '',
      interviewCategory: Interview_Category.Technical,
      interviewRoundMode: InterviewMode['Tatvasoft Office'],
      interviewerLimit: NUMBER_OF_INTERVIEWERS[0],
      interviewStartDateTime: '',
      interviewEndDateTime: '',
      duration: 60,
      description: '',
      interviewTechnology: [],
      skills: [],
      jobDesignation: '',
      isEdit: true,
      isScheduleInterviewEnabled: false,
      currentCompany: '',
      currentCTC: '',
      candidateLocation: '',
      candidateSource: CANDIDATE_SOURCE_TYPE[0]?.code || 1,
      referenceEmployeeName: ''
    });
    setFileName('');
    formik.resetForm();
  };

  useEffect(() => {
    if (show && isUpdatedSuccess && isSucceed) {
      onHide(false);
      formik.resetForm();
      document.body.classList.remove('sidebar-open');
      dispatch(updateData());
      dispatch(updateCandidateData());
      dispatch(apiStatusClear());
      clearData();
      setFileName('');
      setStartDate(null);
      put(setIsUpdatedSuccess(false));
    }
  }, [isSucceed, isUpdatedSuccess]);

  useEffect(() => () => put(setIsUpdatedSuccess(false)), []);

  const formik = useFormik({
    initialValues: candidateData,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, 'Must be 15 characters or less')
        .matches(/^[a-z|A-Z|\s]+$/, 'First Name should only contain characters')
        .required('First Name is required.'),
      lastName: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .matches(/^[a-z|A-Z|\s]+$/, 'Last Name should only contain characters')
        .required('Last Name is required.'),
      mobile: Yup.string().matches(/^[0-9]{10}$/, 'Mobile Number Must Be 10 Digits Only.'),
      secondaryMobile: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile Number Must Be 10 Digits Only.')
        .when('mobile', {
          is: (mobile) => mobile && mobile.length === 10,
          then: Yup.string().notOneOf(
            [Yup.ref('mobile')],
            'Secondary Mobile Number is same as the Mobile Number.'
          )
        }),
      gender: Yup.string(['1', '2']).required('Gender is required.'),
      isEdit: Yup.boolean(),
      isScheduleInterviewEnabled: Yup.boolean(),
      yearOfExperience: Yup.number()
        .min(0, 'Minimum 0 Year.')
        .max(30, 'Maximum 30 Years.')
        .typeError('Only numbers are allowed.')
        .required('Exp. in Years is Required.'),
      monthsOfExperience: Yup.number()
        .min(0, 'Minimum 0 Month.')
        .max(11, 'Maximum 11 Months.')
        .required('Exp. in Months is required.')
        .typeError('Only numbers are allowed.'),
      interviewTechnology: Yup.array()
        .required('Technology is required.')
        .min(1, 'Minimum 1 technology is required.'),
      skills: Yup.array(),
      email: Yup.string().when('isEdit', {
        is: true,
        then: Yup.string().email().required('Email Address is required.')
      }),
      interviewType: Yup.string().when(['isEdit', 'isScheduleInterviewEnabled'], {
        is: true,
        then: Yup.string().required('Interview Type is required.')
      }),
      interviewRoundMode: Yup.string().when(['isEdit', 'isScheduleInterviewEnabled'], {
        is: true,
        then: Yup.string().typeError('Please select interview round mode')
      }),
      interviewerLimit: Yup.number().when(['isEdit', 'isScheduleInterviewEnabled'], {
        is: (isEdit, isScheduleInterviewEnabled) => isEdit && isScheduleInterviewEnabled,
        then: Yup.number()
          .min(1, 'Minimum Limit 1')
          .max(10, 'Maximum  Limit 10')
          .typeError('Only numbers are allowed!')
          .required('Interviewer Limit is required.')
      }),
      interviewerID: Yup.array().when(['isEdit', 'isScheduleInterviewEnabled'], {
        is: (isEdit, isScheduleInterviewEnabled) => isEdit && isScheduleInterviewEnabled,
        then: Yup.array().required('Interviewer is required.').min(1, 'Interviewer is required.')
      }),
      interviewCategory: Yup.string(),
      jobDesignation: Yup.string().when(['isEdit', 'isScheduleInterviewEnabled'], {
        is: true,
        then: Yup.string().nullable()
      }),
      description: Yup.string()
        .trim()
        .max(TEXTAREA_LIMIT.MAX, 'Description should not exceed ${max} characters.')
        .nullable(),
      resumeFilePath: Yup.mixed().test(
        'format',
        'Only the following formats are accepted: .doc, .docx, .pdf',
        (value) => {
          if (!value) {
            return true;
          }
          return resumeValidation(value);
        }
      ),
      candidateLocation: Yup.string('Candidate location must be of type string.').trim(),
      currentCTC: Yup.number('Current CTC must be of type number.')
        .min(0, 'CTC should not be less then 0.')
        .max(99, 'CTC should not be more then 99.')
        .typeError('Only positive numbers are allowed.'),
      currentCompany: Yup.string('Current company must be of type string.')
        .trim()
        .required('Current Company is required.'),
      candidateSource: Yup.number()
        .required('Candidate source is required.')
        .oneOf(
          CANDIDATE_SOURCE_TYPE.map((candidateSource) => candidateSource.code),
          'Valid candidate source is required.'
        ),
      referenceEmployeeName: Yup.string('Candidate reference must be of type string.')
    }),

    onSubmit: (values) => {
      try {
        let addFormData = {};
        if (!ID && values.isScheduleInterviewEnabled) {
          addFormData = {
            interviewStartDateTime: startDate,
            interviewEndDateTime: addMinutes(startDate, values.duration),
            interviewType: values.interviewType,
            ...(values.interviewerID &&
              values.interviewerID?.length && { interviewerID: values.interviewerID }),
            interviewerLimit: values.interviewerLimit,
            interviewCategory: values.interviewCategory,
            interviewRoundMode: values.interviewRoundMode,
            ...(values.jobDesignation && { jobDesignation: values.jobDesignation }),
            ...(values.description && { description: values.description }),
            isInterview: values.isScheduleInterviewEnabled
          };
        }
        const formData = {
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender,
          yearOfExperience: values.yearOfExperience,
          monthsOfExperience: values.monthsOfExperience,
          currentCompany: values.currentCompany,
          candidateTechnology: values.interviewTechnology,
          skills: values.skills,
          currentCTC: values.currentCTC,
          candidateLocation: values.candidateLocation,
          candidateSource: values.candidateSource,
          referenceEmployeeName: values.referenceEmployeeName,
          ...addFormData
        };

        let postData = new FormData();
        Object.keys(formData).forEach((el) => {
          if (typeof el === 'string') {
            postData.append(el, formData[el]);
          } else {
            postData.append(el, JSON.stringify(formData[el]));
          }
        });

        if (ID) {
          // Only encrypt the mobile number if its provided for the edit candidate process.
          if (values.mobile) {
            postData.append('mobile', values.mobile);
          }
          if (values.secondaryMobile) {
            postData.append('secondaryMobile', values.secondaryMobile);
          }
          postData.append('email', values.email);
          fileName && postData.append('resumeFilePath', values.resumeFilePath);
          dispatch(
            editCandidate({
              postData,
              CandidateId: ID
            })
          );
        } else {
          if (values.mobile) {
            postData.append('mobile', values.mobile);
          }
          if (values.secondaryMobile) {
            postData.append('secondaryMobile', values.secondaryMobile);
          }
          postData.append('email', values.email);
          fileName && postData.append('resumeFilePath', values.resumeFilePath);
          dispatch(addCandidate(postData));
        }
      } catch (error) {
        console.log(error);
      }
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name, additionalError = '') => {
    let response = null;
    if (isFormFieldValid(name) || additionalError) {
      response = <small className="p-error">{formik.errors[name] || additionalError}</small>;
    }

    if (name === 'email' && candidates?.existCandidateId) {
      response = (
        <small className="p-error">
          Candidate already added. Click{' '}
          <Link
            to="#"
            className="table-view-popup-link"
            onClick={() => handleView(candidates.existCandidateId)}>
            here
          </Link>{' '}
          for details.
        </small>
      );
    }
    return response;
  };

  useEffect(() => {
    ID && dispatch(getCandidateDetails(ID));
    if (startDate) {
      const endDate = addMinutes(startDate, candidateData.duration);
      const interviewStartTime = moment(startDate).format();
      const interviewEndTime = moment(endDate).format();
      // const technologies =
      //   technologiesID &&
      //   interviewTechnologyType
      //     .filter((tech) => technologiesID.includes(tech.ID))
      //     .map((tech) => tech.Technology)
      //     .toString();
      dispatch(
        allInterviewers(
          interviewStartTime,
          interviewEndTime,
          '',
          '',
          formik.values.interviewType === InterviewType['HR Round']
        )
      );
    }
  }, [dispatch, ID, startDate, formik.values.interviewType]);

  // React useEffect hooks.
  useEffect(() => {
    if (startDate) {
      formik.setFieldValue('interviewerID', []);
    }
  }, [formik.values.interviewType, startDate]);

  const interviewerTemplate = (option) => {
    return (
      <>
        <ReactTooltip />
        <>
          {!option.isAvailable ? (
            <span className="unavailable-interviewer-text-color mr-1">
              {wordCapitalize(option.name)}
            </span>
          ) : (
            <span className="mr-1">{wordCapitalize(option.name)}</span>
          )}
          {!!option?.UMapS?.length && (
            <span className={`${!option.isAvailable && 'unavailable-interviewer-text-color'} mr-1`}>
              [{option?.UMapS?.map((tech) => wordCapitalize(tech?.MapST?.Technology)).join(', ')}]
            </span>
          )}
          {!option.isAvailable && (
            <i
              className="pi pi-exclamation-triangle unavailable-interviewer-text-color"
              data-tip="Might not be available for the above schedule selected"
            />
          )}
        </>
      </>
    );
  };

  console.log(formik.values);
  return (
    <>
      <AvailabilityDetails onHide={onTogglePopup} show={showPopup} />
      <Sidebar
        ID={ID}
        visible={show}
        onHide={() => {
          onHide(true);
          document.body.classList.remove('sidebar-open');
        }}
        position="right"
        className="sidebar-drawer">
        <div className="form-box-wrapper">
          <div className="title-wrapper">
            <p className="card-title">{ID ? 'Edit' : 'Add'} Candidate</p>
            <button className="p-sidebar-close" onClick={() => clearData()}>
              <span className="p-sidebar-close-icon pi pi-times" />
            </button>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className=""
            autoComplete="off"
            encType="multipart/form-data">
            <div className="form-row-wrapper">
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="firstName" text={LABEL_TYPES.FIRST_NAME} isMandatory ishidden />
                    <InputText
                      id="firstName"
                      name="firstName"
                      value={formik.values.firstName}
                      placeholder={LABEL_TYPES.FIRST_NAME + ' *'}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid('firstName'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('firstName')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="lastName" text={LABEL_TYPES.LAST_NAME} isMandatory ishidden />
                    <InputText
                      id="lastName"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      placeholder={LABEL_TYPES.LAST_NAME + ' *'}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid('lastName'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('lastName')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="email" text={LABEL_TYPES.EMAIL} isMandatory ishidden />
                    <InputText
                      id="email"
                      name="email"
                      disabled={ID ? true : false}
                      value={formik.values.email}
                      placeholder={LABEL_TYPES.EMAIL + ' *'}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        if (!formik.errors.email) {
                          dispatch(
                            checkEmailMobile({ email: e.target.value, ...(ID && { userId: ID }) })
                          );
                        }
                      }}
                      className={classNames({
                        'p-invalid': isFormFieldValid('email'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('email')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="mobile" text={LABEL_TYPES.MOBILE} ishidden />
                    <InputText
                      id="mobile"
                      name="mobile"
                      keyfilter="int"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        if (!formik.errors.mobile) {
                          dispatch(
                            checkEmailMobile({ mobile: e.target.value, ...(ID && { userId: ID }) })
                          );
                        }
                      }}
                      placeholder={LABEL_TYPES.MOBILE}
                      className={classNames({
                        'p-invalid': isFormFieldValid('mobile'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('mobile')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="secondaryMobile" text={LABEL_TYPES.SECONDARY_MOBILE} ishidden />
                    <InputText
                      id="secondaryMobile"
                      name="secondaryMobile"
                      keyfilter="int"
                      value={formik.values.secondaryMobile}
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        if (!formik.errors.secondaryMobile) {
                          dispatch(
                            checkEmailMobile({
                              secondaryMobile: e.target.value,
                              ...(ID && { userId: ID })
                            })
                          );
                        }
                      }}
                      placeholder={LABEL_TYPES.SECONDARY_MOBILE}
                      className={classNames({
                        'p-invalid': isFormFieldValid('secondaryMobile'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('secondaryMobile')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="currentCompany"
                      text={LABEL_TYPES.CURRENT_COMPANY}
                      isMandatory
                      ishidden
                    />
                    <InputText
                      id="currentCompany"
                      name="currentCompany"
                      value={formik.values.currentCompany}
                      placeholder={`${LABEL_TYPES.CURRENT_COMPANY} *`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid('currentCompany'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('currentCompany')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="interviewTechnology"
                      text={LABEL_TYPES.TECHNOLOGY}
                      isMandatory
                      isChildren
                      ishidden
                      tooltip="Add names with comma separated"
                    />
                    <MultiSelect
                      filter
                      name="interviewTechnology"
                      id="interviewTechnology"
                      optionLabel="Technology"
                      optionValue="ID"
                      value={formik.values.interviewTechnology}
                      forceSelection
                      options={interviewTechnologyType}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      display="chip"
                      onBlur={formik.handleBlur}
                      placeholder="Select Technology *"
                      className={classNames({
                        'p-invalid': isFormFieldValid('interviewTechnology'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('interviewTechnology')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="skills"
                      text={LABEL_TYPES.TECHNICAL_SKILLS}
                      isChildren
                      ishidden
                      tooltip="Add names with comma separated"
                    />
                    <MultiSelect
                      filter
                      name="skills"
                      id="skills"
                      optionLabel="name"
                      optionValue="value"
                      optionGroupLabel="label"
                      optionGroupChildren="items"
                      value={formik.values.skills}
                      forceSelection
                      options={technologyFilterbySubTechnology(interviewTechnologyType)}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      display="chip"
                      onBlur={formik.handleBlur}
                      placeholder="Select Skills"
                      className={classNames({
                        'p-invalid': isFormFieldValid('skills'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('skills')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="currentCTC"
                      text={LABEL_TYPES.CURRENT_CTC}
                      isMandatory
                      ishidden
                    />
                    <div className="p-inputgroup w-full">
                      <InputText
                        id="currentCTC"
                        name="currentCTC"
                        value={formik.values.currentCTC}
                        placeholder={LABEL_TYPES.CURRENT_CTC}
                        onChange={formik.handleChange}
                        className={classNames({
                          'p-invalid': isFormFieldValid('currentCTC'),
                          'w-full': true
                        })}
                        onBlur={formik.handleBlur}
                        keyfilter={'money'}
                      />
                      <span
                        style={{ backgroundColor: 'inherit' }}
                        className="p-inputgroup-addon add-candidate-lpa">
                        LPA
                      </span>
                    </div>
                  </div>
                  {getFormErrorMessage('currentCTC')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="candidateLocation"
                      text={LABEL_TYPES.CANDIDATE_CURRENT_LOCATION}
                      isMandatory
                      ishidden
                    />
                    <InputText
                      id="candidateLocation"
                      name="candidateLocation"
                      value={formik.values.candidateLocation}
                      placeholder={LABEL_TYPES.CANDIDATE_CURRENT_LOCATION}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid('candidateLocation'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('candidateLocation')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="gender" text={LABEL_TYPES.GENDER} isBold isMandatory />
                    <div className="radio-list-wrapper">
                      <div className="form-group-outer-radiobutton">
                        <RadioButton
                          inputId="male"
                          value={1}
                          name="gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.gender === 1}
                        />
                        <Label htmlFor="male" text={LABEL_TYPES.MALE} isBold />
                      </div>
                      <div className="form-group-outer-radiobutton">
                        <RadioButton
                          inputId="female"
                          value={2}
                          name="gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.gender === 2}
                        />
                        <Label htmlFor="female" text={LABEL_TYPES.FEMALE} isBold />
                      </div>
                    </div>
                  </div>
                  {getFormErrorMessage('gender')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer flex">
                  <div className="custom-form-group mr-4">
                    <Label
                      htmlFor="yearOfExperience"
                      text={LABEL_TYPES.YEAR_EXPERIENCE}
                      isMandatory
                    />
                    <InputText
                      id="yearOfExperience"
                      name="yearOfExperience"
                      placeholder="0-30"
                      type="number"
                      value={formik.values.yearOfExperience}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid('yearOfExperience')
                      })}
                    />
                    {getFormErrorMessage('yearOfExperience')}
                  </div>
                  <div className="custom-form-group">
                    <Label
                      htmlFor="monthsOfExperience"
                      text={LABEL_TYPES.MONTH_EXPERIENCE}
                      isMandatory
                    />
                    <InputText
                      id="monthsOfExperience"
                      name="monthsOfExperience"
                      placeholder="0-11"
                      type="number"
                      value={formik.values.monthsOfExperience}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid('monthsOfExperience')
                      })}
                    />
                    {getFormErrorMessage('monthsOfExperience')}
                  </div>
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="candidateSource"
                      text={LABEL_TYPES.CANDIDATE_SOURCE}
                      isBold
                      isMandatory
                    />
                    <Dropdown
                      id="candidateSource"
                      name="candidateSource"
                      value={formik.values.candidateSource}
                      optionLabel="name"
                      optionValue="code"
                      options={CANDIDATE_SOURCE_TYPE}
                      onChange={formik.handleChange}
                      panelClassName="custom-dropdown-panel"
                      className={classNames({
                        'p-invalid': isFormFieldValid('candidateSource'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('candidateSource')}
                </div>
              </div>
              {formik.values.candidateSource ===
                CANDIDATE_SOURCE_TYPE[CANDIDATE_SOURCE_TYPE.length - 1].code && (
                <>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="referenceEmployeeName"
                          text={LABEL_TYPES.CANDIDATE_SOURCE_REFERENCE}
                          isBold
                        />
                        <InputText
                          id="referenceEmployeeName"
                          name="referenceEmployeeName"
                          value={formik.values.referenceEmployeeName}
                          placeholder={LABEL_TYPES.CANDIDATE_SOURCE_REFERENCE}
                          onChange={formik.handleChange}
                        />
                      </div>
                      {getFormErrorMessage('candidateSource')}
                    </div>
                  </div>
                </>
              )}
              {!ID && (
                <div className="form-col full-width">
                  <div className="form-group-outer">
                    <div className="custom-form-group">
                      <Label
                        htmlFor="isScheduleInterviewEnabled"
                        text={LABEL_TYPES.SCHEDULE_INTERVIEW}
                        isBold
                      />
                      <InputSwitch
                        name="isScheduleInterviewEnabled"
                        checked={formik.values.isScheduleInterviewEnabled}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {formik.values.isScheduleInterviewEnabled && (
                <>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="startDateTime"
                          text={LABEL_TYPES.START_DATE}
                          isBold
                          isChildren
                          isMandatory
                          tooltip="Please select time between 8 AM to 8 PM"
                        />
                        <div className="field flex">
                          <CustomDatePicker
                            value={startDate}
                            onChange={onDateChange}
                            minDateTime={new Date().setDate(new Date().getDate())}
                            minTime={new Date().setHours(0, 0, 0)}
                            maxTime={new Date().setHours(23, 59, 59)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewType"
                          text={LABEL_TYPES.INTERVIEW_TYPE}
                          isMandatory
                          ishidden
                          isBold
                        />
                        <Dropdown
                          name="interviewType"
                          id="interviewType"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.interviewType}
                          options={INTERVIEW_TYPE}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Select Interview Type *"
                          panelClassName="custom-dropdown-panel"
                          className={classNames({
                            'p-invalid': isFormFieldValid('interviewType'),
                            'w-full': true
                          })}
                        />
                      </div>
                    </div>
                    {getFormErrorMessage('interviewType')}
                  </div>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewRoundMode"
                          text={LABEL_TYPES.INTERVIEW_ROUND_MODE}
                          isMandatory
                          ishidden
                        />
                        <Dropdown
                          name="interviewRoundMode"
                          id="interviewRoundMode"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.interviewRoundMode}
                          options={INTERVIEW_MODE}
                          onChange={formik.handleChange}
                          placeholder="Select Interview Mode"
                          panelClassName="custom-dropdown-panel"
                          className={classNames({
                            'p-invalid': isFormFieldValid('interviewRoundMode')
                          })}
                        />
                      </div>
                      {getFormErrorMessage('interviewRoundMode')}
                    </div>
                  </div>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewCategory"
                          text={LABEL_TYPES.INTERVIEW_CATEGORY}
                          isBold
                          isMandatory
                          ishidden
                        />
                        <Dropdown
                          id="interviewCategory"
                          name="interviewCategory"
                          value={formik.values.interviewCategory}
                          optionLabel="name"
                          optionValue="code"
                          options={INTERVIEW_CATEGORY}
                          onChange={formik.handleChange}
                          panelClassName="custom-dropdown-panel"
                          className={classNames({
                            'p-invalid': isFormFieldValid('interviewCategory'),
                            'w-full': true
                          })}
                        />
                      </div>
                      {getFormErrorMessage('interviewCategory')}
                    </div>
                  </div>
                  {RENDER.No && (
                    <div className="form-col full-width">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label
                            htmlFor="jobDesignation"
                            text={LABEL_TYPES.DESIGNATION}
                            isMandatory
                            ishidden
                          />
                          <AutoComplete
                            field="Designation"
                            name="jobDesignation"
                            id="jobDesignation"
                            forceSelection
                            value={designationText}
                            suggestions={designationOptions}
                            placeholder={LABEL_TYPES.DESIGNATION}
                            completeMethod={handleDesignationSearch}
                            onChange={(e) => {
                              setDesignationText(e.target.value);
                              formik.setFieldValue('jobDesignation', e.target.value?.ID);
                            }}
                            onBlur={formik.handleBlur}
                            className={classNames({
                              'p-invalid': isFormFieldValid('jobDesignation'),
                              'w-full': true
                            })}
                          />
                        </div>
                      </div>
                      {getFormErrorMessage('jobDesignation')}
                    </div>
                  )}

                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewerLimit"
                          text={LABEL_TYPES.INTERVIEWER_LIMIT}
                          isMandatory
                          isBold
                        />
                        <Dropdown
                          id="interviewerLimit"
                          name="interviewerLimit"
                          value={formik.values.interviewerLimit}
                          options={NUMBER_OF_INTERVIEWERS}
                          onChange={formik.handleChange}
                          placeholder="Select Number of Interviewers"
                          className="w-full custom-dropdown"
                          panelClassName="custom-dropdown-panel"
                        />
                      </div>
                      {getFormErrorMessage('interviewerLimit')}
                    </div>
                  </div>

                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewerID"
                          text={LABEL_TYPES.INTERVIEWERS}
                          isMandatory
                          isBold
                          isChildren
                          tooltip="Please select Date and Time (time must be between 8 AM to 8 PM)"
                        />
                        <AvailabilityButton onClick={onTogglePopup} />
                        <MultiSelect
                          filter
                          name="interviewerID"
                          id="interviewerID"
                          optionLabel="name"
                          optionValue="code"
                          forceSelection
                          value={formik.values.interviewerID}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          options={interviewerOptions}
                          placeholder="Select Interviewer *"
                          display="chip"
                          itemTemplate={interviewerTemplate}
                          className={classNames({
                            'p-invalid': isFormFieldValid('interviewerID'),
                            'w-full': true
                          })}
                        />
                      </div>
                      {getFormErrorMessage('interviewerID')}
                    </div>
                  </div>

                  {/* Hide Description field */}
                  {/* <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <TextAreaWithCounterLimit
                          placeholder={LABEL_TYPES.DESCRIPTION}
                          labelText={LABEL_TYPES.DESCRIPTION}
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          errorMessage={
                            formik.touched['description'] ? formik.errors['description'] : ''
                          }
                          className={classNames({
                            'p-invalid': isFormFieldValid('description'),
                            'w-full': true
                          })}
                          id="description"
                          name="description"
                          htmlFor="description"
                          rows={1}
                          ishidden
                        />
                      </div>
                    </div>
                  </div> */}
                </>
              )}

              <div className="form-col full-width">
                <div className="form-group-outer upload-document-wrapper">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="file"
                      text={!ID ? LABEL_TYPES.UPLOAD_RESUME : LABEL_TYPES.UPDATE_RESUME}
                      isBold
                    />
                    <input
                      hidden
                      ref={fileRef}
                      type="file"
                      id="resumeFilePath"
                      name="resumeFilePath"
                      onChange={(e) => {
                        formik.setFieldValue('resumeFilePath', e.target.files[0]);
                        setFileName(e.target.files[0].name);
                        setIsFile('');
                      }}
                      accept=".doc, .docx, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      className={classNames({
                        'p-invalid': isFormFieldValid('resumeFilePath')
                      })}
                    />
                  </div>
                  <div className="flex flex-wrap align-items-center">
                    <CustomButton
                      variant="contained"
                      className="gray-btn"
                      onClick={() => {
                        fileRef.current.click();
                      }}>
                      <div>
                        <i className="pi pi-fw pi-upload mr-1" />
                        <span>{!ID ? BUTTON_TYPES.UPLOAD_RESUME : BUTTON_TYPES.UPDATE_RESUME}</span>
                      </div>
                    </CustomButton>
                    {ID ? (
                      isFile ? (
                        <ResumeButton
                          onClick={() => openResumeLink(`${pathBase}/${ID}/${isFile}`)}
                        />
                      ) : (
                        <p>{fileName}</p>
                      )
                    ) : (
                      <p>{fileName}</p>
                    )}
                  </div>
                </div>
                {getFormErrorMessage('resumeFilePath')}
              </div>
            </div>

            <div className="form-btn-wrapper">
              <CustomButton type="submit" variant="contained" disabled={isLoading}>
                {BUTTON_TYPES.SAVE}
              </CustomButton>
              <CustomButton
                variant="contained"
                onClick={() => clearData()}
                color="error"
                className="gray-btn">
                {BUTTON_TYPES.CANCEL}
              </CustomButton>
            </div>
          </form>
        </div>
      </Sidebar>
    </>
  );
};

export default AddEditCandidates;
