import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

const EnableNotification = ({ show }) => {
  useEffect(() => {
    // Save the original overflow value
    const originalOverflow = document.body.style.overflow;
    // Set overflow to hidden
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore the original overflow value
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  return (
    <Dialog header="Please Enable Notifications from browser" visible={show} closable={false}>
      <p>To receive timely updates via notifications,for that please enable notifications:</p>
      <ol>
        <li>Look for the lock or info icon 🔒 in your browser&apos;s address bar.</li>
        <li>Click on this icon to open the site settings.</li>
        <li>Find the &quot;Notifications&quot; option in the dropdown menu.</li>
        <li>Change the setting to &quot;Allow&quot;.</li>
        <li>Refresh this page to apply the changes.</li>
      </ol>
      <p>
        <strong>Why enable notifications?</strong>
      </p>
      <ul>
        <li>Stay updated on any changes.</li>
        <li>Never miss important deadlines or follow-up actions.</li>
      </ul>
    </Dialog>
  );
};

export default EnableNotification;
