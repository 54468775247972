import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

// Components
import Asteric from './Asteric';

// Prime-react Components
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';

const CustomPassword = (props) => {
  const { htmlFor, text, ismandatory, ischildren, isbold, tooltiptext } = props;
  const [onFocus, setOnFocus] = useState(false);
  const [onBlur, setOnBlur] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleOnFocus = () => {
    setOnFocus(true);
    setOnBlur(false);
  };

  const handleOnBlur = () => {
    setOnFocus(false);
    setOnBlur(true);
  };

  return (
    <>
      {
        <>
          <label htmlFor={htmlFor} className={!isbold ? 'text-gray font-bold' : ''}>
            <span
              className={classNames({
                'input-focus': onFocus,
                'text-gray': onBlur
              })}>
              {text}
            </span>
            {ischildren && (
              <>
                <ReactTooltip />
                <i
                  className="custom-target-icon pi pi-info-circle pl-1"
                  data-tip={tooltiptext}
                  style={{ cursor: 'pointer' }}
                />
              </>
            )}
          </label>
          {ismandatory && <Asteric />}
        </>
      }
      <span className="p-input-icon-right">
        <ReactTooltip />
        <i
          className={`pi ${showPassword ? 'pi-eye' : 'pi-eye-slash'}`}
          data-tip={showPassword ? 'click here to hide password' : 'click here to show password'}
          onClick={() => setShowPassword(!showPassword)}
        />
        <InputText
          type={showPassword ? 'text' : 'password'}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          {...props}
        />
      </span>
    </>
  );
};

export default CustomPassword;
