import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

// PrimeReact-Components
import { Menu } from 'primereact/menu';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

// Constants
import { FCM_TOKEN, ROLES, ROUTES } from '../constants/common';

// Redux-Slices
import { logout } from '../redux/slices/auth';
import { setPermissionAndToken } from '../redux/slices/notification';

// Utils
import { getUserRole, wordCapitalize } from '../utils/common';

// Firebase
import { deleteFCMToken } from '../firebase/messaging';

// Images
import AvtarImage from '../pages/profile/AvtarImage';
import userImg from '@assets/images/img_avatar.png';
import siteLogo from '@assets/images/site-logo.svg';
import siteLogoDark from '@assets/images/site-logo-dark.svg';
import { toggleDarkMode } from '../redux/slices/darkMode';
import NotificationModal from '../components/Notifications';
import { readNotificationsAction } from '../redux/actions/profileDetails';

const Topbar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location?.pathname?.toString().split('/').at(-1);
  const portal = location?.pathname?.toString().split('/').at(1);
  const menuRef = useRef(null);
  // const notiRef = useRef(null);

  // State
  const [notificationMenu, setNotificationMenu] = useState();
  const openNotification = Boolean(notificationMenu);

  const { firstName, lastName, userType, userProfile, userId } = useSelector((state) => state.auth);
  const { notifications, pendingFeedback, pendingInvitation } = useSelector(
    (state) => state?.notification
  );
  const { permissionAndToken } = useSelector((state) => state.notification);
  const { mode } = useSelector((state) => state.darkMode);
  const userName = `${wordCapitalize(firstName || '')} ${wordCapitalize(lastName || '')}`;
  const imageName = userProfile || '';
  const encodedPath = imageName
    .split('/')
    .map((p) => encodeURIComponent(p))
    .join('/');

  const logOut = () => {
    localStorage.removeItem(FCM_TOKEN);
    dispatch(
      setPermissionAndToken({ permission: permissionAndToken?.permission || '', token: '' })
    );
    deleteFCMToken();
    confirmDialog({
      header: 'Logout',
      message: 'Are you sure you want to logout?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        dispatch(logout());
        navigate(ROUTES.LOGIN);
      }
    });
  };
  const profile = () => {
    switch (userType) {
      case ROLES.ADMIN:
        return navigate(ROUTES.ADMIN.USER_PROFILE);
      case ROLES.HR:
        return navigate(ROUTES.HR.USER_PROFILE);
      case ROLES.INTERVIEWER:
        return navigate(ROUTES.INTERVIEWER.USER_PROFILE);
      case ROLES.HR_AND_INTERVIEWER:
        return navigate(portal === 'hr' ? ROUTES.HR.USER_PROFILE : ROUTES.INTERVIEWER.USER_PROFILE);
      default:
        return navigate(ROUTES.LOGIN);
    }
  };
  const items = [
    {
      label: userName,
      template: () => {
        return (
          <div className="user-name-wrapper">
            {userProfile === null ? (
              <AvtarImage className="user-img" />
            ) : (
              (
                <img
                  src={`${process.env.REACT_APP_API_ENDPOINT}/public/uploads/profile/${userId}/${encodedPath}`}
                  alt="profile"
                  className="border-circle user-img"
                />
              ) || <img src={userImg} alt="profile" className="border-circle user-img" />
            )}
            {userName}
          </div>
        );
      }
    },
    {
      separator: true
    },
    {
      label: 'My Profile',
      icon: 'pi pi-user',
      command: () => profile()
    },
    ...(userType === ROLES.HR_AND_INTERVIEWER
      ? [
          {
            label: `${portal !== 'hr' ? 'HR' : 'Interviewer'} Portal`,
            icon: 'pi pi-desktop',
            command: () =>
              navigate(`${portal !== 'hr' ? ROUTES.HR.DASHBOARD : ROUTES.INTERVIEWER.DASHBOARD}`)
          }
        ]
      : []),
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => logOut()
    }
  ];

  // const notification_items = [
  //   {
  //     label: 'Notification 1',
  //     template: () => {
  //       return (
  //         <Link className="user-name-wrapper">
  //           <img src={userImg} /> <p>Notification 1</p>
  //         </Link>
  //       );
  //     }
  //   },
  //   {
  //     label: 'Notification 2',
  //     template: () => {
  //       return (
  //         <Link className="user-name-wrapper">
  //           <img src={userImg} /> <p>Notification 2</p>
  //         </Link>
  //       );
  //     }
  //   }
  // ];

  // Handle notification menu
  const openNotificationModal = (event) => {
    setNotificationMenu(event.currentTarget);
  };

  const closeNotificationModal = () => {
    setNotificationMenu(null);
  };

  const readNotification = (ids) => {
    dispatch(readNotificationsAction(ids));
  };

  return (
    <>
      <div className="layout-topbar-block">
        <div className="layout-topbar">
          <div className="layout-topbar-logo">
            <button
              type="button"
              className="p-link layout-menu-button layout-topbar-button"
              onClick={props.onToggleMenuClick}>
              <i className="pi pi-bars default-ic" />
              <i className="pi pi-times active-menu-ic" />
            </button>
            <span className="logo-text">
              {path === 'jobdesignations' || path === 'jobdescriptions'
                ? path.substring(0, 3) + ' ' + path.substring(3, 20)
                : Object.keys(location?.pathname?.toString().split('/')).length === 3
                ? location?.pathname?.toString().split('/').at(-1).replace('-', ' ')
                : location?.pathname?.toString().split('/').at(-2) ||
                  location?.pathname?.toString().split('/').at(-1)}
            </span>
            <Link to={ROUTES.HOME} className="topbar-mobile-logo">
              <img src={siteLogo} className="light-theme-logo" />
              <img src={siteLogoDark} className="dark-theme-logo" />
            </Link>
          </div>

          <button
            type="button"
            className="p-link layout-topbar-menu-button layout-topbar-button"
            onClick={(event) => menuRef.current.toggle(event)}>
            <i className="pi pi-ellipsis-v" />
          </button>

          <div
            className={classNames('layout-topbar-menu lg:flex origin-top', {
              'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive
            })}>
            <div className="flex align-items-center topbar-menu-list">
              {(userType === ROLES.INTERVIEWER ||
                (userType === ROLES.HR_AND_INTERVIEWER && portal !== 'hr')) && (
                <>
                  {pendingFeedback && (
                    <div
                      className="user-role blink cursor-pointer"
                      onClick={() => navigate(ROUTES.INTERVIEWER.INTERVIEW_SCHEDULED)}>
                      Update Interview Feedback
                    </div>
                  )}
                  {pendingInvitation && (
                    <div
                      className="user-role blink cursor-pointer"
                      onClick={() => navigate(ROUTES.INTERVIEWER.INTERVIEW_INVITATION)}>
                      Accept Invitation
                    </div>
                  )}
                </>
              )}
              <div className="theme-mode topbar-menu-item">
                <Button
                  type="button"
                  className={classNames(
                    'p-button-rounded p-button-text border-none primary-icon-btn theme-button',
                    {
                      mode: mode
                        ? document.body.classList.add('dark-theme')
                        : document.body.classList.remove('dark-theme')
                    }
                  )}
                  onClick={() => {
                    dispatch(toggleDarkMode());
                  }}>
                  <i className="pi pi-moon default-icon"></i>
                  <i className="pi pi-sun darkmode-icon"></i>
                </Button>
              </div>
              {userType !== ROLES.ADMIN && (
                <div className="theme-mode topbar-menu-item">
                  <Button
                    type="button"
                    className="p-button-rounded p-button-text border-none primary-icon-btn theme-button"
                    onClick={(e) => {
                      openNotificationModal(e);
                    }}>
                    <i className="pi pi-bell position-relative">
                      {!!notifications?.length &&
                        !!notifications?.filter((notification) => !notification.IsRead)?.length && (
                          <span className="unread-notification" />
                        )}
                    </i>
                  </Button>
                </div>
              )}
              {/* <div className="flex align-items-center topbar-menu-item notification-wrapper">
              <Menu
                model={notification_items}
                popup
                ref={notiRef}
                id="popup_menu"
                className="popup_menu notification-popup"
              />
              <div className="notification-menu">
                <div className="menu-link">
                  <Button
                    type="button"
                    className="p-button-rounded p-button-text border-none primary-icon-btn"
                    icon="pi pi-bell"
                    onClick={(event) => notiRef.current.toggle(event)}>
                    <span className="notification-number">4</span>
                  </Button>
                </div>
              </div>
            </div> */}
              <div
                className="flex align-items-center topbar-menu-item profile-wrapper"
                onClick={(event) => menuRef.current.toggle(event)}>
                <span className="user-role">{getUserRole(userType)}</span>
                <Menu
                  model={items}
                  popup
                  ref={menuRef}
                  id="popup_menu"
                  className="popup_menu user-menu-popup"
                />
                <div className="user-dropdown">
                  <div className="menu-link">
                    {userProfile === null ? (
                      <AvtarImage className="user-img" />
                    ) : (
                      (
                        <img
                          src={`${process.env.REACT_APP_API_ENDPOINT}/public/uploads/profile/${userId}/${encodedPath}`}
                          alt="profile"
                          className="border-circle user-img"
                        />
                      ) || <AvtarImage className="user-img" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationModal
        open={openNotification}
        loading={false}
        anchor={notificationMenu}
        notifications={notifications}
        handleClose={closeNotificationModal}
        handleClick={(ids) => readNotification(ids)}
      />
    </>
  );
};

export default Topbar;
