import { ROLES, TABLE_NAME } from '../constants/common';

export const getDataFromLocalstorage = (STORAGE_KEY) => {
  const data = localStorage.getItem(STORAGE_KEY);
  if (data) return JSON.parse(data);
  return null;
};

export const setDataInLocalstorage = (STORAGE_KEY, data) => {
  return localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};

export const wordCapitalize = (word) => {
  return `${word?.charAt(0).toUpperCase()}${word?.slice(1)}`;
};

export const toLowerCaseText = (word) => {
  return `${word?.toLowerCase()}`;
};

export const getUserRole = (userRole) => {
  switch (userRole) {
    case ROLES.ADMIN:
      return 'Admin';
    case ROLES.INTERVIEWER:
      return 'Interviewer';
    case ROLES.HR:
      return 'HR';
    case ROLES.HR_AND_INTERVIEWER:
      return 'HR and Interviewer';
    case ROLES.CANDIDATE:
      return 'Candidate';
    default:
      return '';
  }
};

export const resumeValidation = (value) => {
  return (
    value &&
    (value.type === '.doc' ||
      value.type === '.docx' ||
      value.type === 'application/pdf' ||
      value.type === 'application/msword' ||
      value.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
  );
};

export const getUserTableName = (tableName) => {
  switch (tableName) {
    case TABLE_NAME.INTERVIEWERS:
      return 'Add New Interviewer';
    case TABLE_NAME.HR:
      return 'Add New HR';
    case TABLE_NAME.DESIGNATION:
      return 'Add New Designation';
    case TABLE_NAME.TECHNOLOGY:
      return 'Add New Technology';
    case TABLE_NAME.CANDIDATE:
      return 'Add New Candidate';
    case TABLE_NAME.INTERVIEW:
      return 'Add New Interview';
    case TABLE_NAME.COMMON_PRAMETERS:
      return 'Add New Parameter';
    default:
      return '';
  }
};

export const downloadPdf = async (pathBase, ID, FilePath) => {
  const response = await fetch(`${pathBase}/${ID}/${FilePath}`);
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = FilePath || 'Resume.pdf';
  a.click();

  URL.revokeObjectURL(url);
};
