import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EqualHeight } from 'react-equal-height';

// Images
import interviewIc from '@assets/images/total-interview-ic.svg';
import candidateIc from '@assets/images/candidates-ic.svg';

// Prime-React Components
import DoughnutChart from '../../components/DoughnutChart';
import PieChart from '../../components/PieChart';
// import UpcommingDoughnutChart from '../../components/UpcommingDoughnutChart';
import AvailableInterviewersBarChart from '../../components/AvailableInterviewersBarChart';

// Redux-Actions
import { getAdminDashboardCount, getInterviewerRound } from '../../redux/actions/admin';
import { STATUS_INTERVIEW, USER_TYPE_TEXT } from '../../constants/common';
import { getScheduleInterviewCountByHR } from '../../redux/actions/hr';
import moment from 'moment';
import { getDateWithDay } from '../../utils/date';

const Schedule = () => {
  const dispatch = useDispatch();
  const { dashboardCountData, interviewRoundData } = useSelector((state) => state.adminDashboard);

  useEffect(() => {
    dispatch(getAdminDashboardCount());
    dispatch(getInterviewerRound());
    dispatch(
      getScheduleInterviewCountByHR(
        getDateWithDay(moment().startOf('day').toDate()),
        getDateWithDay(moment().endOf('day').toDate())
      )
    );
  }, [dispatch]);

  //for cards
  function parseData(data) {
    if (!data) {
      return { candidates: [], hrs: [], interviewers: [] };
    } else {
      const { candidates, hrs, interviewers } = data;
      return {
        candidates: [{ name: 'Total', value: candidates?.totalCandidates }],
        hrs: [
          { name: 'Total', value: hrs?.totalHRs },
          { name: 'Active', value: hrs?.activeHRs },
          { name: 'Inactive', value: hrs?.inactiveHRs }
        ],
        interviewers: [
          { name: 'Total', value: interviewers?.totalInterviewers },
          { name: 'Active', value: interviewers?.activeInterviewers },
          { name: 'Inactive', value: interviewers?.inactiveInterviewers }
        ]
      };
    }
  }
  const { interviewers } = parseData(dashboardCountData);

  return (
    <div className="hr-dashboard">
      <div className="white-box-grid dashboard-card-list">
        <div className="white-box-item small-width">
          <div className="white-box-item dashboard-card-item full-width dashboard-card-item-height dashboard-card-upper-item">
            <div className="white-box align-center">
              <div className="card-content full-width">
                <div className="card-title-block align-items-center">
                  <div className="content">
                    <p className="number">
                      {(!!interviewRoundData?.length &&
                        interviewRoundData.filter(
                          (round) =>
                            round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_PENDING ||
                            round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE ||
                            round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE ||
                            round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_PENDING ||
                            round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE ||
                            round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE ||
                            round.Status === STATUS_INTERVIEW.MANAGEMENT_PENDING ||
                            round.Status === STATUS_INTERVIEW.MANAGEMENT_SCHEDULE ||
                            round.Status === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE ||
                            round.Status === STATUS_INTERVIEW.PRACTICAL_PENDING ||
                            round.Status === STATUS_INTERVIEW.PRACTICAL_SCHEDULE ||
                            round.Status === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE ||
                            round.Status === STATUS_INTERVIEW.HR_PENDING ||
                            round.Status === STATUS_INTERVIEW.HR_SCHEDULE ||
                            round.Status === STATUS_INTERVIEW.HR_RESCHEDULE
                        )?.length) ||
                        0}
                    </p>
                    <p className="card-title">{USER_TYPE_TEXT.PLANED_INTERVIEWS}</p>
                  </div>
                  <div className="icon-block">
                    <i>
                      <img src={interviewIc} alt="interview" />
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="white-box-item dashboard-card-item full-width dashboard-card-item-height dashboard-card-lower-item">
            <div className="white-box align-center">
              <div className="card-content full-width">
                <div className="card-title-block align-items-center">
                  <div className="content">
                    <p className="number">{interviewers[0]?.value || 0}</p>
                    <p className="card-title">Total available Interviewers</p>
                  </div>
                  <div className="icon-block">
                    <i className="candidate-ic">
                      <img src={candidateIc} alt="candidate" />
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EqualHeight>
          <DoughnutChart />
        </EqualHeight>
        <EqualHeight>
          <PieChart />
          {/* <UpcommingDoughnutChart /> */}
          <AvailableInterviewersBarChart />
          {/* <TechnologyBarchart /> */}
        </EqualHeight>
      </div>
    </div>
  );
};

export default Schedule;
