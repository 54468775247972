export const lightOptions = {
  aspectRatio: 1,
  borderWidth: 0,
  offset: 2,
  plugins: {
    datalabels: {
      font: {
        size: 16
      }
    },
    legend: {
      position: 'bottom',
      padding: {
        top: 50
      },
      labels: {
        usePointStyle: true,
        boxWidth: 6,
        boxHeight: 6,
        color: localStorage.getItem('darkMode') === 'true' ? 'rgba(228, 230, 244, 1)' : '#8b8787',
        font: {
          size: 13,
          family: "'Public Sans', sans-serif",
          weight: 300
        }
      }
    }
  }
};

export const pieOptions = {
  aspectRatio: 1.2,
  borderWidth: 0,
  plugins: {
    datalabels: {
      font: {
        size: 16
      }
    },
    legend: {
      position: 'bottom',
      padding: {
        top: 50
      },
      labels: {
        usePointStyle: true,
        boxWidth: 6,
        boxHeight: 6,
        color: localStorage.getItem('darkMode') === 'true' ? 'rgba(228, 230, 244, 1)' : '#8b8787',
        font: {
          size: 13,
          family: "'Public Sans', sans-serif",
          weight: 300
        }
      }
    }
  }
};

export const barOptions = {
  aspectRatio: 1,
  borderWidth: 0,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        title: (context) => {
          return context[0].label.replaceAll(',', ' ');
        }
      }
    },
    datalabels: {
      font: {
        size: 16
      },
      formatter: (v) => (v ? v : '')
    },
    legend: {
      display: false,
      position: 'bottom',
      padding: {
        top: 50
      },
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        color: localStorage.getItem('darkMode') === 'true' ? 'rgba(228, 230, 244, 1)' : '#8b8787',
        font: {
          size: 10,
          family: "'Public Sans', sans-serif",
          weight: 300
        }
      }
    }
  },
  scales: {
    y: {
      ticks: {
        color: localStorage.getItem('darkMode') === 'true' ? 'rgba(228, 230, 244, 1)' : '#8b8787',
        font: {
          family: "'Public Sans', sans-serif",
          size: 13,
          weight: 300
        }
      }
    },
    x: {
      ticks: {
        color: localStorage.getItem('darkMode') === 'true' ? 'rgba(228, 230, 244, 1)' : '#8b8787',
        font: {
          family: "'Public Sans', sans-serif",
          size: 13,
          weight: 300
        },
        minRotation: 0,
        maxRotation: 0
      }
    }
  }
};
