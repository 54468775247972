import React from 'react';
import { useSelector } from 'react-redux';

// Prime-React-Components
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { TabView, TabPanel } from 'primereact/tabview';

const InterviewDetails = (props) => {
  const { isLoading } = useSelector((state) => state.apiStatus);
  const { onHide, show, data } = props;
  return (
    <Dialog
      header="Interview Details"
      className={classNames('card interview-details-dialog', { hidden: isLoading })}
      visible={show}
      onHide={onHide}>
      <TabView>
        {data &&
          Object.keys(data).map((tabName, i) => {
            return tabName && data[tabName] ? (
              <TabPanel header={tabName} key={i}>
                <div className="content-wrap">
                  {data &&
                    Object.keys(data[tabName]).map((detail, i) => {
                      return data[tabName][detail] ? (
                        <div className="detail-row" key={i}>
                          <p className="title">
                            <span>{detail}</span>
                            <span className="colon">:</span>
                          </p>
                          <div className="content">
                            <span>{data[tabName][detail]}</span>
                          </div>
                        </div>
                      ) : null;
                    })}
                </div>
              </TabPanel>
            ) : null;
          })}
      </TabView>
    </Dialog>
  );
};

export default InterviewDetails;
