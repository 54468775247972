import moment from 'moment';
import React from 'react';
import { Menu, MenuItem } from '@mui/material';

export default function NotificationModal(props) {
  // Props
  const { open, anchor, notifications, handleClose, handleClick } = props;

  return (
    <Menu
      anchorEl={anchor}
      id="notification-menu"
      className="dropdown_menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}>
      <div className="heading">
        <h2>Notifications</h2>
      </div>
      <div className="notification-modal">
        {notifications?.length
          ? notifications.map((notification, index) => {
              return (
                <MenuItem
                  key={index}
                  className={`user-info ${!notification?.IsRead && 'unread'}`}
                  onClick={() => handleClick([notification.ID])}>
                  <div className="user-data">
                    <p dangerouslySetInnerHTML={{ __html: notification.Message }} />
                    <span>{moment(notification.DateCreated).fromNow()}</span>
                  </div>
                </MenuItem>
              );
            })
          : 'No notifications'}
      </div>
      <div className="all-read">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleClick(
              notifications
                ?.filter((notification) => !notification.IsRead)
                .map((notification) => notification.ID)
            );
          }}>
          Mark all as read
        </a>
      </div>
    </Menu>
  );
}
