import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components
import Label from '../../components/Label';
import CustomButton from '../../components/CustomButton';

// Prime-React Components
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Sidebar } from 'primereact/sidebar';

// Redux-Actions
import {
  addJobDesignation,
  editJobDesignation,
  getJobDesignationById
} from '../../redux/actions/designation';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { updateData } from '../../redux/slices/designation';

// Constants
import { BUTTON_TYPES, LABEL_TYPES } from '../../constants/common';

const AddEditJobDesignation = (props) => {
  const dispatch = useDispatch();
  const { show, ID, onHide } = props;
  const { designationDetails } = useSelector((state) => state.designation);
  const { isSucceed, isLoading } = useSelector((state) => state.apiStatus);
  const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false);
  const [jobDesignationData, setJobDesignationData] = useState({
    designation: ''
  });

  useEffect(() => {
    ID && dispatch(getJobDesignationById(ID));
  }, [dispatch, ID]);

  useEffect(() => {
    if (ID && designationDetails && Object.keys(designationDetails).length !== 0) {
      setJobDesignationData({
        designation: `${designationDetails?.Designation}`
      });
    }
  }, [designationDetails]);

  useEffect(() => {
    if (show && isUpdatedSuccess && isSucceed) {
      onHide(false);
      formik.resetForm();
      document.body.classList.remove('sidebar-open');
      dispatch(updateData());
      dispatch(apiStatusClear());
      setJobDesignationData({
        designation: ''
      });
      setIsUpdatedSuccess(false);
    }
  }, [isSucceed]);

  useEffect(() => () => setIsUpdatedSuccess(false), []);

  const formik = useFormik({
    initialValues: jobDesignationData,
    validationSchema: Yup.object({
      designation: Yup.string()
        .trim()
        .required('Designation is required.')
        .max(30, 'Must be 30 characters or less')
    }),
    onSubmit: (values) => {
      if (ID) {
        dispatch(
          editJobDesignation({
            formdata: { ...values },
            ID: ID
          })
        );
      } else {
        dispatch(
          addJobDesignation({
            designation: values.designation
          })
        );
      }
      setIsUpdatedSuccess(true);
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <Sidebar
      ID={ID}
      visible={show}
      onHide={() => {
        onHide(true);
        document.body.classList.remove('sidebar-open');
      }}
      position="right"
      className="sidebar-drawer">
      <div className="form-box-wrapper">
        <div className="title-wrapper">
          <p className="card-title">{ID ? 'Edit' : 'Add'} Job Designation</p>
          <button
            className="p-sidebar-close"
            onClick={() => {
              onHide(false);
              document.body.classList.remove('sidebar-open');
              setJobDesignationData({
                designation: ''
              });
              formik.resetForm();
            }}>
            <span className="p-sidebar-close-icon pi pi-times" />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
          <div className="form-row-wrapper">
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label for="designation" text={LABEL_TYPES.DESIGNATION} isMandatory ishidden />
                  <span className="p-input-icon-right">
                    <InputText
                      id="designation"
                      name="designation"
                      placeholder={LABEL_TYPES.DESIGNATION + ' *'}
                      value={formik.values.designation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid('designation')
                      })}
                    />
                  </span>
                </div>
                {getFormErrorMessage('designation')}
              </div>
            </div>
          </div>
          <div className="form-btn-wrapper">
            <CustomButton type="submit" variant="contained" className="" disabled={isLoading}>
              {BUTTON_TYPES.SAVE}
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={() => {
                onHide(false);
                document.body.classList.remove('sidebar-open');
                setJobDesignationData({
                  designation: ''
                });
                formik.resetForm();
              }}
              color="error"
              className="gray-btn">
              {BUTTON_TYPES.CANCEL}
            </CustomButton>
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default AddEditJobDesignation;
