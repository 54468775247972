import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

// Api-Services
import {
  changeUserPasswordApi,
  editUserProfileDetailsApi,
  getUserPermissionApi,
  getUserProfileDetailsApi,
  userPermissionApi
} from '../apis/profileDetails';

// Constants
import {
  READ_NOTIFICATIONS,
  USER_NOTIFICATIONS,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PERMISSION_CHANGE_REQUEST,
  USER_PERMISSION_REQUEST,
  USER_PROFILE_DETAILS,
  USER_PROFILE_EDIT_REQUEST
} from '../../constants/redux';
import { RESPONSE_STATUS_CODES, STORAGE_KEY } from '../../constants/common';

// Slices
import { apiSuccess } from '../slices/apiStatus';
import { getUserPermission, getUserProfileDetails } from '../slices/profileDetails';
import { login } from '../slices/auth';
import { getDataFromLocalstorage } from '../../utils/common';
import { notificationsApi, readNotificationsApi } from '../apis/notification';
import { setUserNotifications } from '../slices/notification';

function* getUserProfileDetailsSaga() {
  try {
    const response = yield call(getUserProfileDetailsApi);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getUserProfileDetails(result));
    }
  } catch (error) {
    console.log(error);
  }
}
function* editUserPeofileDetailSaga(action) {
  const authData = getDataFromLocalstorage(STORAGE_KEY);
  const fName = action.payload.get('firstName');
  const lName = action.payload.get('lastName');
  const imageName = action.payload.get('profileImage')?.name;
  try {
    const response = yield call(editUserProfileDetailsApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.UPDATE) {
      // Update the username, first name and last name to local storage.
      authData.userName = fName + ' ' + lName;
      authData.firstName = fName;
      authData.lastName = lName;
      if (imageName) {
        authData.userProfile = fName + '-' + imageName;
      } else {
        authData.userProfile = null;
      }
      yield put(apiSuccess());
      yield put(login(authData));
      toast.success('Details have been Updated Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  } catch (error) {
    console.log(error);
  }
}
function* changeUserPasswordSaga(action) {
  try {
    const response = yield call(changeUserPasswordApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.UPDATE) {
      yield put(apiSuccess());
      toast.success('Password Updated Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* changeUserPermissionSaga(action) {
  try {
    const response = yield call(userPermissionApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(apiSuccess());
      if (action.payload.emailNotification) {
        toast.success('Email Subscription Updated Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      } else {
        toast.success('Notification Subscription Updated Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* getUserPermissionSaga() {
  const response = yield call(getUserPermissionApi);
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    yield put(getUserPermission(response.data.data));
  }
}

function* getUserNotificationsSaga() {
  const response = yield call(notificationsApi);
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    yield put(
      setUserNotifications({
        notifications: response?.data?.data?.notifications || [],
        pendingFeedback: response?.data?.data?.pendingFeedback || false,
        pendingInvitation: response?.data?.data?.pendingInvitation || false
      })
    );
  }
}

function* readNotificationsSaga(action) {
  const response = yield call(readNotificationsApi, action.payload);
  if (
    response?.status === RESPONSE_STATUS_CODES.OK ||
    response?.status === RESPONSE_STATUS_CODES.UPDATE
  ) {
    const response = yield call(notificationsApi);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(
        setUserNotifications({
          notifications: response?.data?.data?.notifications || [],
          pendingFeedback: response?.data?.data?.pendingFeedback || false,
          pendingInvitation: response?.data?.data?.pendingInvitation || false
        })
      );
    }
  }
}

function* watcherProfileDetailsSaga() {
  yield takeLatest(USER_PROFILE_DETAILS, getUserProfileDetailsSaga);
  yield takeLatest(USER_PROFILE_EDIT_REQUEST, editUserPeofileDetailSaga);
  yield takeLatest(USER_PASSWORD_CHANGE_REQUEST, changeUserPasswordSaga);
  yield takeEvery(USER_PERMISSION_CHANGE_REQUEST, changeUserPermissionSaga);
  yield takeEvery(USER_PERMISSION_REQUEST, getUserPermissionSaga);
  yield takeEvery(USER_NOTIFICATIONS, getUserNotificationsSaga);
  yield takeEvery(READ_NOTIFICATIONS, readNotificationsSaga);
}

export default watcherProfileDetailsSaga;
