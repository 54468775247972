import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import * as Yup from 'yup';

// Images
import logoImg from '@assets/images/site-logo.svg';
import logodarkImg from '@assets/images/site-logo-dark.svg';
import siteLogoTatva from '@assets/images/site-logo-tatva.svg';
// Components
import CustomButton from '../../components/CustomButton';

// Prime-React Components
import CustomPassword from '../../components/CustomPassword';
import { classNames } from 'primereact/utils';

// Redux-Actions
import { resetPassword } from '../../redux/actions/auth';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';

// Constants
import { BUTTON_TYPES, LABEL_TYPES, ROUTES } from '../../constants/common';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSucceed, isLoading } = useSelector((state) => state.apiStatus);
  const { token } = useParams();

  useEffect(() => {
    if (isSucceed) {
      navigate(ROUTES.LOGIN);
      dispatch(apiStatusClear());
    }
  }, [isSucceed, navigate, dispatch]);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      token: token
    },

    validationSchema: Yup.object({
      password: Yup.string().required('Password is required.'),
      confirmPassword: Yup.string()
        .required('Confirm Password is required.')
        .oneOf([Yup.ref('password'), null], 'Password does not match!!!')
    }),
    onSubmit: () => {
      try {
        dispatch(resetPassword(formik.values));
        isSucceed && formik.resetForm();
      } catch (error) {
        console.log(error);
      }
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <div className="authentication-wrapper reset-pwd">
      <div className="tatva-logo-wrap">
        <img src={siteLogoTatva} alt="Tatvasoft" />
      </div>
      <div className="authentication-inner">
        <div className="login-container">
          <span className="site-logo">
            <img src={logoImg}></img>
            <img src={logodarkImg} className="dark-theme-logo"></img>
          </span>
          <h2>Reset Password</h2>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="field">
              <CustomPassword
                htmlFor="password"
                text={LABEL_TYPES.NEW_PASSWORD}
                ismandatory={+true}
                isbold={+true}
                id="password"
                name="password"
                placeholder="New Password *"
                value={formik.values.password}
                onChange={formik.handleChange}
                toggleMask
                className={classNames({
                  'p-invalid': isFormFieldValid('password')
                })}
                feedback={false}
              />
              {getFormErrorMessage('password')}
            </div>
            <div className="field">
              <CustomPassword
                htmlFor="confirmPassword"
                text={LABEL_TYPES.CONFIRM_PASSWORD}
                ismandatory={+true}
                isbold={+true}
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password *"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                toggleMask
                className={classNames({
                  'p-invalid': isFormFieldValid('confirmPassword')
                })}
                feedback={false}
              />
              {getFormErrorMessage('confirmPassword')}
            </div>
            <CustomButton type="submit" variant="contained" className="w-full" disabled={isLoading}>
              {BUTTON_TYPES.SAVE}
            </CustomButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
