import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';

// Api-Services
import {
  addHRApi,
  deleteHRApi,
  editHRApi,
  getAvailableScheduleApi,
  hrlistApi,
  getInterviewTechnologyApi,
  getInterviewTechnologyBySearchApi,
  getJobDesignationApi,
  getScheduleInterviewApi,
  getJobDesignationBySearchApi,
  getInterviewerAvailiblityByFilter,
  getInterviewersBySearchNameApi,
  getScheduleInterviewCountByHRApi
} from '../apis/hr';

// Constants
import {
  ADD_HR_REQUEST,
  DELETE_HR_REQUEST,
  HR_AVAILABLE_SCHEDULE_REQUEST,
  HR_EDIT_REQUEST,
  HR_INTERVIEW_SEARCH_TECHNOLOGY,
  HR_INTERVIEW_TECHNOLOGY,
  HR_JOB_DESIGNATION,
  HR_LIST_REQUEST,
  HR_SCHEDULE_INTERVIEW_REQUEST,
  HR_JOB_DESIGNATION_BY_SEARCH,
  HR_INTERVIEWER_AVAILABLITY_BY_FILTER,
  HR_INTERVIEWERERS_LIST_BY_SEARCHNAME,
  HR_SCHEDULE_INTERVIEW_COUNT
} from '../../constants/redux';
import { RESPONSE_STATUS_CODES } from '../../constants/common';

// Redux-Actions
import {
  addHrData,
  deleteHR,
  getAvailableSchedule,
  getInterviewTechnology,
  getJobDesignation,
  getScheduleInterview,
  getJobDesignationBySearch,
  interviewTechnologyBySearch,
  interviewerAvailiblityByFilter,
  interviewersListBySearchName,
  updateLoading,
  getScheduleInterviewsCountByHR
} from '../slices/hr';

import { apiSuccess } from '../slices/apiStatus';

function* HrListSaga(action) {
  try {
    const response = yield call(hrlistApi, { ...action.payload });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const data = result.hrs;
      const pagination = result.pagination;
      yield put(addHrData({ data, pagination }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* addHR(action) {
  try {
    const response = yield call(addHRApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.CREATED) {
      toast.success('HR Added Successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteHRData(action) {
  try {
    yield call(deleteHRApi, action.payload);
    yield put(deleteHR(action.payload));
  } catch (error) {
    console.log(error);
  }
}

function* editHRData(action) {
  try {
    const response = yield call(editHRApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.SUCCESS) {
      toast.success('Details have been Updated Successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* getAvailableScheduleSaga() {
  try {
    const response = yield call(getAvailableScheduleApi);
    const data = response.data.data;
    yield put(getAvailableSchedule(data));
  } catch (error) {
    console.log(error);
  }
}

function* getScheduleInterviewSaga(action) {
  try {
    const response = yield call(getScheduleInterviewApi, action.payload);
    const data = response.data.data;
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(getScheduleInterview(data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getJobDesignationSaga() {
  try {
    const response = yield call(getJobDesignationApi);
    const result = response.data.data;
    yield put(getJobDesignation(result));
  } catch (error) {
    console.log(error);
  }
}

function* getInterviewTechnologySaga() {
  try {
    const response = yield call(getInterviewTechnologyApi);
    const result = response.data.data;
    yield put(getInterviewTechnology(result));
  } catch (error) {
    console.log(error);
  }
}

function* getJobDesignationBySearchSaga(action) {
  const searchText = action.payload;
  try {
    const response = yield call(getJobDesignationBySearchApi, searchText);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getJobDesignationBySearch(result));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getInterviewTechnologyBySearchSaga(action) {
  const searchText = action.payload;
  try {
    const response = yield call(getInterviewTechnologyBySearchApi, searchText);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(interviewTechnologyBySearch(result));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getInterviewersListBySearchNameSaga(action) {
  const { payload: filterObj } = action;
  try {
    const response = yield call(getInterviewersBySearchNameApi, filterObj);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(interviewersListBySearchName(result));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getInterviewerAvailiblityByFilterSaga(action) {
  try {
    yield put(updateLoading({ loading: true }));
    const response = yield call(getInterviewerAvailiblityByFilter, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const data = result.availableInterviewer;
      const pagination = result.pagination;
      yield put(interviewerAvailiblityByFilter({ data, pagination }));
      yield put(updateLoading({ loading: false }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getScheduleInterviewCountByHRSaga(action) {
  try {
    yield put(updateLoading({ loading: true }));
    const response = yield call(getScheduleInterviewCountByHRApi, { ...action.payload });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getScheduleInterviewsCountByHR(result));
      yield put(updateLoading({ loading: false }));
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* watcherHrListSaga() {
  yield takeEvery(HR_LIST_REQUEST, HrListSaga);
  yield takeEvery(ADD_HR_REQUEST, addHR);
  yield takeEvery(DELETE_HR_REQUEST, deleteHRData);
  yield takeEvery(HR_EDIT_REQUEST, editHRData);
  yield takeEvery(HR_AVAILABLE_SCHEDULE_REQUEST, getAvailableScheduleSaga);
  yield takeEvery(HR_SCHEDULE_INTERVIEW_REQUEST, getScheduleInterviewSaga);
  yield takeEvery(HR_JOB_DESIGNATION, getJobDesignationSaga);
  yield takeEvery(HR_INTERVIEW_TECHNOLOGY, getInterviewTechnologySaga);
  yield takeEvery(HR_JOB_DESIGNATION_BY_SEARCH, getJobDesignationBySearchSaga);
  yield takeEvery(HR_INTERVIEW_SEARCH_TECHNOLOGY, getInterviewTechnologyBySearchSaga);
  yield takeEvery(HR_INTERVIEWER_AVAILABLITY_BY_FILTER, getInterviewerAvailiblityByFilterSaga);
  yield takeEvery(HR_INTERVIEWERERS_LIST_BY_SEARCHNAME, getInterviewersListBySearchNameSaga);
  yield takeEvery(HR_SCHEDULE_INTERVIEW_COUNT, getScheduleInterviewCountByHRSaga);
}
