import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import DataTable from '../../components/DataTable';
import UserDetails from '../../components/popup/UserDetails';
import { ActionButtons, Switch } from '../../components/ActionButtons';
import AddEditInterviewer from './AddEditInterviewer';

// Redux-Actions
import { userDetailsAction } from '../../redux/actions/userDetails';
import {
  getInterviewersList,
  deleteInterviewer,
  userStatus
} from '../../redux/actions/interviewers';

// Prime-React Components
import { confirmDialog } from 'primereact/confirmdialog';

// Constants
import {
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  ACTION_TYPE,
  TABLE_NAME
} from '../../constants/common';
import { INTERVIEWERS_COLUMNS } from '../../constants/tableColumns';

// Utils
import { wordCapitalize, getUserRole } from '../../utils/common';
import { debounce } from '../../utils/debounce';

// Apis
import { editUserDetailsAction } from '../../redux/actions/admin';
import { getUpdatedYearsAndMonths } from '../../utils/date';
import ReactTooltip from 'react-tooltip';
import { technologyFilterbySubTechnology } from '../../utils/array';

const InterviewerList = () => {
  const dispatch = useDispatch();
  const technologyListData = useSelector((state) => state.hrs.interviewTechnologyType);
  const { interviewers, userDetails } = useSelector((state) => state);
  const [interviewerId, setInterviewerId] = useState(null);
  const [editInterviewerId, setEditInterviewerId] = useState(null);
  const [interviewerList, setInterviewerList] = useState([]);
  const [interviewerExport, setInterviewerExport] = useState([]);
  const [interviewerDetails, setInterviewerDetails] = useState([]);
  const [tableStates, setTableStates] = useState({
    searchValue: '',
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    sortBy: 'DateModified',
    technology: '',
    orderBy: 'desc'
  });
  const [showPopup, setShowPopup] = useState(false);
  const onTogglePopup = () => setShowPopup((prevState) => !prevState);
  const [visibleRight, setVisibleRight] = useState(false);
  const onToggleSiderbar = () => setVisibleRight(!visibleRight);

  useEffect(() => {
    dispatch(getInterviewersList(tableStates));
    if (interviewerId && interviewerId === editInterviewerId) {
      dispatch(userDetailsAction(interviewerId));
    }
  }, [
    dispatch,
    tableStates,
    interviewers?.isDeleted,
    interviewers?.isUpdated,
    interviewers?.isAdded
  ]);

  useEffect(() => {
    const modifiedList = interviewers?.data?.map((user) => {
      const name = `${wordCapitalize(user.FirstName)} ${wordCapitalize(user.LastName)}`;
      let updatedYearsAndMonths = getUpdatedYearsAndMonths(
        user.YearsOfExperience,
        user?.MonthsOfExperience,
        user.ExpirenceUPdatedDateTime
      );
      return {
        ...user,
        Experience:
          `${
            updatedYearsAndMonths.updatedYears ? `${updatedYearsAndMonths.updatedYears} Years` : ''
          }` +
          `${
            updatedYearsAndMonths.updatedMonths
              ? ` ${updatedYearsAndMonths.updatedMonths} Months`
              : ''
          }`,
        Name: (
          <Link to="#" className="table-view-popup-link" onClick={() => handleView(user.ID)}>
            {name}
          </Link>
        ),
        'Technical Skills': (
          <ul className="technology-list">
            {user.UMapS && user?.UMapS?.length > 0
              ? user.UMapS?.map((tech) => (
                  <li
                    key={tech?.MapST?.Technology}
                    className="technology-badge"
                    style={{ background: tech?.MapST?.TechnologyColor }}>
                    {tech?.MapST?.Technology}
                  </li>
                ))
              : []}
          </ul>
        ),
        action: (
          <div className="flex align-items-center">
            <Switch UserStatus={user.UserStatus} onChange={(e) => handleActive(e, user.ID)} />
            <ActionButtons onClick={(actionType, e) => handleRowActions(actionType, user.ID, e)} />
          </div>
        )
      };
    });
    setInterviewerList(modifiedList);
  }, [interviewers]);

  useEffect(() => {
    const modifiedList = interviewers?.data?.map((user) => {
      const name = `${wordCapitalize(user.FirstName)} ${wordCapitalize(user.LastName)}`;
      return {
        Experience:
          `${user.YearsOfExperience} Years` +
          `${user?.MonthsOfExperience ? ` ${user?.MonthsOfExperience} Months` : ''}`,
        Name: name,
        'Technical Skills': user.TechStack?.map((tech) => wordCapitalize(tech)).join(', ')
      };
    });
    setInterviewerExport(modifiedList);
  }, [interviewers]);

  useEffect(() => {
    interviewerId && dispatch(userDetailsAction(interviewerId));
  }, [dispatch, interviewerId]);

  const modifyUserDetails = useCallback(() => {
    if (userDetails?.ID) {
      let updatedYearsAndMonths = getUpdatedYearsAndMonths(
        userDetails.YearsOfExperience,
        userDetails?.MonthsOfExperience,
        userDetails.ExpirenceUPdatedDateTime
      );
      setInterviewerDetails([
        {
          title: 'Name',
          value: `${wordCapitalize(userDetails?.FirstName || '')} ${wordCapitalize(
            userDetails?.LastName || ''
          )}`
        },
        { title: 'Role', value: getUserRole(userDetails?.UserTypeId || '') },
        { title: 'Gender', value: userDetails?.Gender === 1 ? 'Male' : 'Female' || '' },
        {
          title: 'Skills',
          value: userDetails?.UMapS?.length
            ? userDetails?.UMapS?.map((tech) => wordCapitalize(tech?.MapST?.Technology)).join(' | ')
            : ''
        },
        {
          title: 'Experience',
          value:
            `${
              updatedYearsAndMonths.updatedYears ? updatedYearsAndMonths.updatedYears : ''
            } Years` +
            `${
              updatedYearsAndMonths.updatedMonths
                ? ` ${updatedYearsAndMonths.updatedMonths} Months`
                : ''
            }`
        },
        {
          title: 'Email Address',
          value: (
            <div className="center">
              <span>{userDetails?.Email || '--'}</span>
              {userDetails?.Email && (
                <>
                  <i
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={`copyIcon_${userDetails?.Email}`}
                    id={`copyIcon_${userDetails?.Email}`}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={`copyIcon_${userDetails?.Email}`}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.Email);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Mobile',
          value: (
            <div className="center">
              <span>{userDetails?.Mobile || '--'}</span>
              {userDetails?.Mobile && (
                <>
                  <i
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={`copyIcon_${userDetails?.Mobile}`}
                    id={`copyIcon_${userDetails?.Mobile}`}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={`copyIcon_${userDetails?.Mobile}`}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.Mobile);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        }
      ]);
    }
  }, [userDetails]);

  useEffect(() => {
    modifyUserDetails();
  }, [userDetails, modifyUserDetails]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleRowActions = (actionType, rowId) => {
    if (actionType === ACTION_TYPE.VIEW) {
      onTogglePopup();
      setInterviewerId(rowId);
    } else if (actionType === ACTION_TYPE.EDIT) {
      onToggleSiderbar();
      setEditInterviewerId(rowId);
      dispatch(editUserDetailsAction(rowId));
      document.body.classList.add('sidebar-open');
    } else if (actionType === ACTION_TYPE.DELETE) {
      confirmDialog({
        header: 'Delete',
        message: 'Are you sure you want to delete?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => dispatch(deleteInterviewer(rowId))
      });
    }
  };

  const handleView = (ID) => {
    if (ID) {
      onTogglePopup();
      setInterviewerId(ID);
    }
  };

  const handleActive = (event, id) => {
    dispatch(userStatus({ id, UserStatus: event.value }));
  };

  const handleAdd = () => {
    setEditInterviewerId(null);
    onToggleSiderbar();
    setVisibleRight(true);
    document.body.classList.add('sidebar-open');
  };

  const handleStatusFilter = (value) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        technology: value || ''
      };
    });
  };

  return (
    <>
      <UserDetails data={interviewerDetails} onHide={onTogglePopup} show={showPopup} />
      <AddEditInterviewer ID={editInterviewerId} onHide={onToggleSiderbar} show={visibleRight} />
      <DataTable
        name={TABLE_NAME.INTERVIEWERS}
        searchPlaceHolder="Search by Interviewer name"
        columns={INTERVIEWERS_COLUMNS}
        data={interviewerList}
        exportData={interviewerExport}
        totalRecords={interviewers?.pagination?.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onSort={handleSort}
        onActive={handleActive}
        onAdd={() => handleAdd()}
        isDropdownMultiSelect
        isdropdownGroup
        dropdownPlaceHolder="Select Technology"
        onStatusFilter={handleStatusFilter}
        dropdown={
          technologyListData?.length ? technologyFilterbySubTechnology(technologyListData) : []
        }
      />
    </>
  );
};

export default InterviewerList;
