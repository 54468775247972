import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import {
  addCandidateNote,
  deleteCandidateNote,
  editCandidateNote,
  getCandidateNote
} from '../../redux/actions/candidates';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

export default function Notes(props) {
  const { onHide, candidateId, data = [], show } = props;
  const dispatch = useDispatch();
  const [selectedNote, setSelectedNote] = useState('');
  const [selectedNoteId, setSelectedId] = useState(null);
  const auth = useSelector((state) => state.auth);

  const refreshNotes = () => {
    setTimeout(() => dispatch(getCandidateNote(candidateId)), [200]);
  };

  const addNote = () => {
    if (selectedNote?.trim() !== '') {
      dispatch(addCandidateNote({ candidateId, notes: { note: selectedNote?.trim() } }));
      refreshNotes();
      setSelectedNote('');
    }
  };

  const editNote = (id, note) => {
    setSelectedNote(note);
    setSelectedId(id);
  };

  const updateNote = () => {
    if (selectedNote.trim() !== '') {
      dispatch(
        editCandidateNote({ noteId: selectedNoteId, notes: { note: selectedNote?.trim() } })
      );
      refreshNotes();
      setSelectedNote('');
      setSelectedId(null);
    }
  };

  const deleteNote = (id) => {
    dispatch(deleteCandidateNote(id));
    refreshNotes();
  };

  return (
    <Dialog
      header="Notes"
      className={classNames('card overflow-hidden resize-none')}
      visible={show}
      style={{ width: '850px' }}
      onHide={onHide}>
      <div className="notesWrap">
        <div className="flex">
          <InputText
            value={selectedNote}
            placeholder="Add a new note"
            onChange={(e) => setSelectedNote(e.target.value)}
          />
          {selectedNoteId ? (
            <Button style={{ height: '50px' }} onClick={updateNote}>
              Update
            </Button>
          ) : (
            <Button style={{ height: '50px' }} onClick={addNote}>
              Add
            </Button>
          )}
        </div>
        <div className="notesListWrap">
          <List
            sx={{
              position: 'relative',
              overflow: 'auto',
              minWidth: 600,
              maxHeight: 300
            }}>
            {data?.length > 0 ? (
              data.map((note, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <>
                      {selectedNoteId !== note?.ID && note?.NoteWriter?.ID === auth?.userId && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            editNote(note.ID, note.Notes);
                          }}>
                          <i className="pi pi-fw pi-pencil"></i>
                        </IconButton>
                      )}
                      {note?.NoteWriter?.ID === auth?.userId && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => deleteNote(note.ID)}>
                          <i className="pi pi-fw pi-trash"></i>
                        </IconButton>
                      )}
                    </>
                  }>
                  <ListItemText
                    primary={note.Notes}
                    sx={{
                      width: 'calc(100% - 210px)',
                      boxSizing: 'border-box',
                      paddingRight: '20px',
                      wordBreak: 'break-all'
                    }}
                  />
                  <ListItemText
                    sx={{
                      width: '100px',
                      boxSizing: 'border-box',
                      paddingRight: '20px'
                    }}
                    primary={
                      <Typography variant="body2" style={{ fontSize: 12 }}>
                        {note?.DateModified
                          ? moment(note.DateModified).format('DD-MM-YYYY HH:mm A')
                          : ''}
                      </Typography>
                    }
                  />
                  <ListItemText
                    sx={{
                      width: '110px',
                      boxSizing: 'border-box',
                      paddingRight: '15px'
                    }}
                    primary={
                      <Typography variant="body2" style={{ fontSize: 12 }}>
                        {note?.NoteWriter
                          ? (note?.NoteWriter?.FirstName || '') +
                            ' ' +
                            (note?.NoteWriter?.LastName || '')
                          : ''}
                      </Typography>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <p className="pt-2 text-center">No notes available.</p>
            )}
          </List>
        </div>
      </div>
    </Dialog>
  );
}
