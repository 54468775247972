import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  pagination: {},
  InterviewDetails: {}
};

const interviewsSlice = createSlice({
  name: 'interviews',
  initialState,
  reducers: {
    getInterviews(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination, isUpdated: false, isAdded: false };
    },
    getInterviewsStatus(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination };
    },
    getInterviewDetails(state, action) {
      const InterviewDetails = { ...action.payload };
      return { ...state, InterviewDetails: InterviewDetails };
    },
    getTecharams(state, action) {
      const TechparamsDetails = action.payload;
      const { requiredParams, commonParams } = TechparamsDetails;
      return {
        ...state,
        RequiredParams: requiredParams,
        CommonParams: commonParams
      };
    },
    redirectionOnStatusChange(state, action) {
      return {
        ...state,
        ...action.payload
      };
    },
    interviewTableStatus(state, action) {
      return { ...state, interviewRoundStatus: action.payload };
    },
    interviewTableType(state, action) {
      return { ...state, interviewRoundType: action.payload };
    },
    getHistoryLogs(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination };
    },
    updateData(state) {
      return { ...state, isUpdated: true, isAdded: true };
    },
    cancelInterview(state, action) {
      const interviewId = action.payload;
      const clonedInterviewData = [...state.data];
      const interviewList = clonedInterviewData.filter((interview) => interview.ID !== interviewId);
      return { ...state, data: interviewList, isCancelled: true };
    },
    cancelInterviewRound(state) {
      return { ...state, isCancelled: !state?.isCancelled };
    }
  }
});

export const {
  getInterviews,
  getInterviewDetails,
  redirectionOnStatusChange,
  getTecharams,
  interviewTableStatus,
  interviewTableType,
  getInterviewsStatus,
  getHistoryLogs,
  updateData,
  cancelInterview,
  cancelInterviewRound
} = interviewsSlice.actions;
export default interviewsSlice.reducer;
